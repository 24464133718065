import React from 'react';
import { AiOutlineUser } from 'react-icons/ai'
import { FaRegEnvelope } from 'react-icons/fa'
import { BsPencil } from 'react-icons/bs'
import { RiSendPlane2Line } from 'react-icons/ri'
// import { FaBusinessTime } from "react-icons/fa";
import { FaPhone } from "react-icons/fa";
// import { AiOutlineHome } from "react-icons/ai"
import axios from 'axios';
import { useState } from 'react';
import sectiondata from '../../store/store';
function AskQuestionField({ title }) {
    const [formData, setFormData] = useState({
        name: '',
        phone: '',
        email: '',
        comment: ''
    });
    const [alertMessage, setAlertMessage] = useState(false)
    const [succ, setSucc] = useState(false)
    const [disable, setDisable] = useState(false)
    const handleChange = (e) => {
        setFormData({
          ...formData,
          [e.target.name]: e.target.value,
        });
      };
    
      const handleSubmit = async (e) => {
        e.preventDefault();
        setDisable(true)
        try {
          const response = await axios.post(`${sectiondata.apiurl}/hindu/admin/submit-msg`, formData);  // Adjust the API endpoint as needed
          if (response.status === 200) {
            // setAlertMessage('Message submitted successfully!Wait for the call .');
            setFormData({ name: '', phone: '', email: '', comment: '' });
              // navigate('/');
              setSucc(true);
              setTimeout(() => {
                setSucc(false);
              }, 3000);
    
            }
            setDisable(false)
        } catch (error) {
            setDisable(false)
            setAlertMessage(true);
            setTimeout(() => {
              setAlertMessage(false);
            }, 3000);
        //   setAlertMessage('Error submitting message. Please try again.');
        }
      }
    return (
        <>
            <div className="faq-forum">
                <div className="billing-form-item">
                    {title ? (
                        <div className="billing-title-wrap">
                            <h3 className="widget-title pb-0">{title}</h3>
                            <div className="title-shape margin-top-10px"></div>
                        </div>
                    ) : ''}
                    <div className="billing-content">
                        <div className="contact-form-action">
                            <form onSubmit={handleSubmit}>
                                <div className="input-box">
                                    <label className="label-text">Your name</label>
                                    <div className="form-group">
                                        <span className="form-icon"><AiOutlineUser /></span>
                                        <input className="form-control" required value={formData.name} type="text" name="name" onChange={handleChange} placeholder="Your name" />
                                    </div>
                                </div>
                                <div className="input-box">
                                    <label className="label-text">Phone Number</label>
                                    <div className="form-group">
                                        <span className="form-icon"><FaPhone/></span>
                                        <input className="form-control" type="number" value={formData.phone}  name="phone" onChange={handleChange} placeholder="Phone Number" />
                                    </div>
                                </div>
                                <div className="input-box">
                                    <label className="label-text">Your email</label>
                                    <div className="form-group">
                                        <span className="form-icon"><FaRegEnvelope /></span>
                                        <input className="form-control" required type="email" name="email" value={formData.email} onChange={handleChange} placeholder="Email address" />
                                    </div>
                                </div>
                                {/* <div className="input-box">
                                    <label className="label-text">Business Name</label>
                                    <div className="form-group">
                                        <span className="form-icon"><FaBusinessTime  /></span>
                                        <input className="form-control" type="text" name="name" placeholder="Business Name" />
                                    </div>
                                </div> */}
                                {/* <div className="input-box">
                                    <label className="label-text">No of year in Operation (Since how long you are in this business)</label>
                                    <div className="form-group">
                                        <span className="form-icon"></span>
                                        <input className="form-control" type="text" name="name" placeholder="No of year in Operation (Since how long you are in this business)" />
                                    </div>
                                </div> */}
                                <div className="input-box">
                                    <label className="label-text">Message</label>
                                    <div className="form-group">
                                        <span className="form-icon"><BsPencil /></span>
                                        <textarea required className="message-control form-control" value={formData.comment } onChange={handleChange} name="comment" placeholder="Message"></textarea>
                                    </div>
                                </div>
                                {/* <div className="input-box">
                                    <label className="label-text">
                                    Address </label>
                                    <div className="form-group">
                                        <span className="form-icon"><AiOutlineHome/></span>
                                        <input className="form-control" type="text" name="name" placeholder="
Address " />
                                    </div>
                                </div> */}
                                {/* <div className="input-box">
                                    <label className="label-text">
                                    Name of the Person (from HEF) Referring your Business </label>
                                    <div className="form-group">
                                        <span className="form-icon"></span>
                                        <input className="form-control" type="text" name="name" placeholder="
Name of the Person (from HEF) Referring your Business " />
                                    </div>
                                </div> */}
                                 {succ && (
                    <div
                      class="alert alert-success "
                      style={{ margin: "auto" }}
                      role="alert"
                    >
                      Message Send Successfully
                    </div>
                  )}
                  {alertMessage && (
                    <div
                      class="alert alert-danger "
                      style={{ margin: "auto" }}
                      role="alert"
                    >
                       Something Went Wrong Try Again
                    </div>
                  )}
                                <div className="btn-box">
                                    <button type="submit" disabled={disable} className="theme-btn border-0">
                                        <i><RiSendPlane2Line /></i> send message
                                    </button>
                                </div>

                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default AskQuestionField;