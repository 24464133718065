import React, { useEffect, useState } from "react";
import Navbar from "./Navbar";
import HeaderAuthorAccess from "../other/account/HeaderAuthorAccess";
// import Logo from "./Logo";
import log from "../../assets/logo-2.png"
// import logosrc from "../../assets/images/logo.png";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
export default function GeneralHeader() {
  const [yes, setYes] = useState(false);
  const navigate = useHistory();
  useEffect(() => {
    if (navigate?.location?.pathname === "/") {
      setYes(true);
    }
  }, []);
  // console.log(navigate?.location?. pathname)
  useEffect(() => {
    const headerwrap = document.querySelector(".header-menu-wrapper");
    const rootElement = document.documentElement;

    function handleScroll() {
      if (rootElement.scrollTop > 10) {
        headerwrap.classList.add("header-fixed");
      } else {
        headerwrap.classList.remove("header-fixed");
      }
    }
    document.addEventListener("scroll", handleScroll);
  });
  return (
    <>
      <header className={`header-area header-fixed`}>
        <div className={`header-menu-wrapper ${yes && "header-fixed"}`}>
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="menu-full-width">
                  {/* Logo */}
                  <div className="logoo">
                    <h1 onClick={() => { navigate.push("/") }} class="elementor-heading-title elementor-size-large">
                      HINDU BUSINESS DIRECTORY
                    </h1>
                    {/* <img src={log} alt="logo" onClick={() => { navigate.push("/") }} /> */}
                  </div>

                  {/* Navbar */}
                  <Navbar />

                  {/* Author Access */}
                  <HeaderAuthorAccess />
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
}
