import React, { useState, useEffect } from "react";
import { IoIosLink } from "react-icons/io";
// import { AiOutlineEye } from "react-icons/ai";
import { FiPhone } from "react-icons/fi";
import sectiondata from "../../store/store";
// import { FaRegCalendarCheck } from "react-icons/fa";
import Slider from "react-slick";
import { FaRegEnvelope, FaRegTrashAlt, FaRegEdit } from "react-icons/fa";
import { IoMdStar } from "react-icons/io";
import { useHistory } from "react-router-dom";

import team3 from "../../assets/smallimage.jpeg";
// Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import axios from "axios";
import Cookies from "js-cookie";
import { each } from "lodash";
const responsive = [
  {
    breakpoint: 1024,
    settings: {
      slidesToShow: 3,
      slidesToScroll: 3,
      infinite: true,
      dots: true,
    },
  },
  {
    breakpoint: 768,
    settings: {
      slidesToShow: 2,
      slidesToScroll: 2,
      infinite: true,
      dots: true,
    },
  },
  {
    breakpoint: 600,
    settings: {
      slidesToShow: 2,
      slidesToScroll: 2,
      initialSlide: 2,
    },
  },
  {
    breakpoint: 480,
    settings: {
      slidesToShow: 1,
      slidesToScroll: 1,
    },
  },
];

function PlaceOne({ places, pop, del }) {
  const [load, setload] = useState(true)
  useEffect(() => {
    // Set a timeout to change the load state after 3 seconds
    const timer = setTimeout(() => {
      setload(false);
    }, 3000);

    // Clean up the timeout if the component unmounts before the timer completes
    return () => clearTimeout(timer);
  }, []);
  const history = useHistory();
  const handleclick = (data) => {
    // history('/listing-detail', { state: data })
    history.push({
      pathname: `/listing-details/${data.compName}`,
      // query string
      state: data,
    });
  };
  const handlecl = (data) => {
    // console.log(data)
    // history('/listing-detail', { state: data })
    history.push({
      pathname: `/edit-listing`,
      // query string
      state: data,
    });
  };
  // console.log(places, "data");
  const [succ, setSucc] = useState(false);
  const [err, seterr] = useState(false);
  const [errmesage, seterrmessage] = useState(false);
  const [disable, setDisable] = useState(false);

  const handledelete = (id) => {
    setDisable(true);
    axios
      .delete(`${sectiondata.apiurl}/hindu/company/compDelete`, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${Cookies.get("token")}`,
        },
      })
      .then((res) => {
        history.push("/dashboard");
      })
      .catch((err) => {
        history.push("/dashboard");
      });
  };
  return (
    <>
      {pop ? (
        <div className="row mt-5">
          <div className="col-lg-12">
            <Slider
              className="places-carousel"
              dots={true}
              infinite={true}
              slidesToScroll={2}
              arrows={false}
              slidesToShow={3}
              centerMode={false}
              centerPadding="50px"
              autoplay={false}
              responsive={responsive}
            >
              {places.map((item, index) => {
                return (
                  <div
                    className="card-item"
                    key={index}
                    onClick={() => {
                      handleclick(item);
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    {/* <a href={ `/listing-details`} onClick={()=>{handleclick(item)}} className="card-image-wrap"> */}
                    <div className="card-image">
                      {item.image[0] ? (
                        <img
                          src={item?.image[0]}
                          className="card__img"
                          alt="Place"
                        />
                      ) : (
                        <img src={team3} className="card__img" alt="Place" />
                      )}

                      <span className={"badge"}>{item?.category}</span>
                      {/* <span className="badge-toggle" data-toggle="tooltip" data-placement="bottom" title="22 Likes">
                                            <FiHeart />
                                        </span> */}
                    </div>
                    {/* </a> */}
                    <div className="card-content-wrap">
                      <div className="card-content">
                        <a href={item?.titleUrl}>
                          <h5
                            className="card-meta"
                            style={{ margin: "0", padding: "0" }}
                          >
                            {item?.category}
                          </h5>
                          <h4 className="card-title">
                            {/* {item.title} */}
                            <i>{item?.compName}</i>
                          </h4>
                          <p className="card-sub">
                            {item?.city} {item?.state}
                          </p>
                        </a>
                        {/* <a href={item.authorUrl} className="author-img">
                                            <img src={item.author} alt="author-img" />
                                        </a> */}
                        <ul className="info-list padding-top-20px">
                          {item?.contactNo && (
                            <li>
                              <span className="la d-inline-block">
                                <FiPhone />
                              </span>{" "}
                              {item?.contactNo}
                            </li>
                          )}
                          {item?.email && (
                            <li>
                              <span className="la d-inline-block">
                                <FaRegEnvelope />
                              </span>
                              {item?.email}
                            </li>
                          )}
                          {item?.websiteURl && (
                            <li>
                              <span className="la d-inline-block">
                                <IoIosLink />
                              </span>{" "}
                              <a href={item?.websiteURl}>Website</a>
                            </li>
                          )}
                          {/* <li>
                                                <span className="la d-inline-block"><FaRegCalendarCheck /></span> {item.date}
                                            </li> */}
                        </ul>
                      </div>
                      <div className="rating-row">
                        <div className="rating-rating">
                          {[1, 2, 3].map((rating, index) => {
                            return (
                              <span key={index}>
                                <IoMdStar />
                              </span>
                            );
                          })}
                          <span className="rating-count">{3}</span>
                        </div>
                        {/* <div className="listing-info">
                                            <ul>
                                                <li><span className="info__count"><AiOutlineEye /></span> {item.view}</li>
                                                <li>
                                                    <span className="info__save" data-toggle="tooltip" data-placement="top" title="Bookmark">
                                                        <FiHeart />
                                                    </span>
                                                </li>
                                            </ul>
                                        </div> */}
                      </div>
                    </div>
                  </div>
                );
              })}
            </Slider>
          </div>
        </div>
      ) : (
        <div style={{ width: "100%" }} className=" mt-5">
          <div
            style={{ display: "grid", gap: "12px", flexWrap: "wrap" }}
            className="display-flex specialone"
          >
            {/* <Slider className="places-carousel" dots={true} infinite={true} slidesToScroll={2} arrows={false} slidesToShow={3} centerMode={false} centerPadding="50px" autoplay={false} responsive={responsive}> */}
            {
              places.map((item, index) => {
                return (
                  <div
                    className="card-item correcting-card"
                    key={index}
                    style={{ cursor: "pointer" }}
                  >
                    {/* <a href={`/listing-details`}  className="card-image-wrap"> */}
                    <div
                      className="card-image correcting-img"
                      onClick={() => {
                        handleclick(item);
                      }}
                    >
                      {item?.image[0] ? (
                        <img
                          src={item?.image[0]}
                          className="card__img"
                          alt="Place"
                        />
                      ) : (
                        <img src={team3} className="card__img" alt="Place" />
                      )}

                      <span className={"badge"}>{item?.category}</span>
                      {/* <span className="badge-toggle" data-toggle="tooltip" data-placement="bottom" title="22 Likes">
                                            <FiHeart />
                                        </span> */}
                    </div>
                    {/* </a> */}
                    <div className="card-content-wrap">
                      <div className="card-content">
                        <a href={item?.titleUrl}>
                          <h5
                            className="card-meta"
                            style={{ margin: "0", padding: "0" }}
                          >
                            {item?.category}
                          </h5>
                          <h4 className="card-title">
                            <i>{item?.compName}</i>
                          </h4>
                          <p className="card-sub">
                            {item?.city} {item?.state}
                          </p>
                        </a>
                        {/* <a href={item.authorUrl} className="author-img">
                                            <img src={item.author} alt="author-img" />
                                        </a> */}
                        <ul className="info-list padding-top-20px">
                          <li>
                            <span className="la d-inline-block">
                              <FiPhone />
                            </span>{" "}
                            {item?.contactNo}
                          </li>
                          <li>
                            <span className="la d-inline-block">
                              <FaRegEnvelope />
                            </span>
                            {item?.email}
                          </li>
                          {item?.websiteURl && (
                            <li>
                              <span className="la d-inline-block">
                                <IoIosLink />
                              </span>{" "}
                              <a href={item?.websiteURl}>Website</a>
                            </li>
                          )}
                          {/* <li>
                                                <span className="la d-inline-block"><FaRegCalendarCheck /></span> {item.date}
                                            </li> */}
                        </ul>
                      </div>
                      <div className="rating-row">
                        <div className="rating-rating">
                          {[1, 2, 3].map((rating, index) => {
                            return (
                              <span key={index}>
                                <IoMdStar />
                              </span>
                            );
                          })}
                          <span className="rating-count">{3}</span>
                        </div>
                        {del && (
                          <div className="listing-info">
                            <ul>
                              <li>
                                <button
                                  disabled={disable}
                                  type="button"
                                  className="theme-btn border-0"
                                  onClick={() => handledelete()}
                                >
                                  <span className="la">
                                    <FaRegTrashAlt />
                                  </span>{" "}
                                  {disable ? "Deleting..." : "delete"}
                                </button>
                                <button
                                  style={{ marginLeft: "12px" }}
                                  type="button"
                                  className="theme-btn border-0"
                                  onClick={() => {
                                    handlecl(item);
                                  }}
                                >
                                  <span className="la">
                                    <FaRegEdit />
                                  </span>{" "}
                                  Edit
                                </button>
                              </li>
                            </ul>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                );
              })}
        
            {/* </Slider> */}
          </div>
        </div>
      )}
    </>
  );
}

export default PlaceOne;
