import React from "react";
import GeneralHeader from "../../components/common/GeneralHeader";
import Breadcrumb from "../../components/common/Breadcrumb";
// import PopularCategories from "../../components/other/categories/PopularCategories";
// import NewsLetter from "../../components/other/cta/NewsLetter";
import Footer from "../../components/common/footer/Footer";
import cat from "../../assets/images/category.jfif";
import { useEffect, useState } from "react";
import ScrollTopBtn from "../../components/common/ScrollTopBtn";
// import PopularCategoriesMore from "../../components/other/categories/PopularCategoriesMore";
import breadcrumbimg from "../../assets/images/login.jpg";
import sectiondata from "../../store/store";
import axios from "axios";
import { Link } from "react-router-dom";
const state = {
  breadcrumbImg: breadcrumbimg,
};
function AllCategories() {
  const [data, setData] = useState([]);
  const [prevpage, setprevpage] = useState(1);
  const [curpage, setcurpage] = useState(1);
  const [pagelist, setpagelist] = useState([]);
  const arrayRange = (start, stop, step) =>
    Array.from(
      { length: (stop - start) / step + 1 },
      (value, index) => start + index * step
    );
  function divideArrayIntoChunks(array, chunkSize) {
    const result = [];
    for (let i = 0; i < array.length; i += chunkSize) {
      const chunk = array.slice(i, i + chunkSize);
      result.push(chunk);
    }
    return result;
  }
  useEffect(() => {
    axios
      .get(`${sectiondata.apiurl}/hindu/company/getAllCategories`)
      .then((res) => {
        const a = res?.data?.data?.length;
        // console.log(res.data.data.length);

        setData(res?.data?.data);
        const b = Math.ceil(a / 12);
        // console.log(b)
        const page = arrayRange(1, b, 1);
        // console.log(page)
        setpagelist(page);
      })
      .catch((err) => {
        // console.log(err);
      });
  }, []);

  return (
    <main className="all-categories">
      {/* Header */}
      <GeneralHeader />

      {/* Breadcrumb */}
      <Breadcrumb
        CurrentPgTitle="All Categories"
        MenuPgTitle="Categories"
        img={state.breadcrumbImg}
      />

      <section className="cat-area padding-top-40px padding-bottom-80px">
        <div className="container">
          <div
            style={{
              display: "flex",
              alignContent: "flex-start",
              marginBottom: "10px",
            }}
          >
            {pagelist.length > 0 &&
              pagelist.map((e, i) => {
                return (
                  <span
                    onClick={() => {
                      setcurpage(e);

                      if (e === 1) {
                        // alert(
                        //   1, "setprev"
                        // )
                        setprevpage(1);
                      } else {
                        // alert(
                        //   (e * 12) + 1, "setprev"
                        // )
                        setprevpage(i * 12 + 1);
                      }

                      // setprevpage(e - 1 * 10)
                    }}
                    style={{
                      cursor: "pointer",
                      borderRadius: "100%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      background: i + 1 === curpage ? "#ff8200" : "white",
                      color: i + 1 === curpage && "white",
                      margin: " 0 3px",
                      padding: "12px",
                      height: "10px",
                      width: "10px",
                    }}
                  >
                    {e}
                  </span>
                );
              })}
          </div>
          {data.length > 0 ? (
            <div className="row">
              {/* {prevpage}"jk"{(curpage * 12) + 1} */}
              {data.slice(prevpage, curpage * 12 + 1).map((each, index) => {
                if (each.categoryName) {
                  return (
                    <div className="col-lg-3 column-td-6" key={index}>
                      <div className="category-item mb-4 mt-0 ml-0 mr-0 p-0">
                        <figure className="category-fig m-0">
                          <img src={cat} alt="category" className="cat-img" />
                          <figcaption className="fig-caption">
                            <Link
                              to={`/search?category=${each?.categoryName}`}
                              className="cat-fig-box"
                            >
                              <div className="icon-element mb-3">
                                {each.count}
                              </div>
                              <div className="cat-content">
                                <h4 className="cat__title mb-3">
                                  {each?.categoryName}
                                </h4>
                                <span className="badge">
                                  {each?.categoryName}
                                </span>
                              </div>
                            </Link>
                          </figcaption>
                        </figure>
                      </div>
                    </div>
                  );
                }
              })}

              {/* <PopularCategories
              pop={true}
              catitems={sectiondata.popularcategories.categories}
            /> */}
              {/* <PopularCategoriesMore catitems={sectiondata.popularcategories.morecats} /> */}
            </div>
          ) : (
            <div className="row">
              {[1, 2, 3, 4].map((each) => {
                return (
                  <div className="col-lg-3 column-td-6" key={each}>
                    <div className="category-item mb-4 mt-0 ml-0 mr-0 p-0">
                      <figure className="category-fig m-0">
                        <div key={each} class="card-img skeleton">
                          <p> Loading..</p>
                        </div>

                      </figure>
                    </div>
                  </div>
                  //
                );
              })}
            </div>
          )}
        </div>
      </section>

      {/* NewsLetter */}
      {/* <NewsLetter newsLetterContent={sectiondata.calltoactions.newsletters} /> */}

      {/* Footer */}
      <Footer />

      <ScrollTopBtn />
    </main>
  );
}

export default AllCategories;
