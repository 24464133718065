import React from "react";
import GeneralHeader from "../components/common/GeneralHeader";
import Breadcrumb from "../components/common/Breadcrumb";
import RecoverPassBox from "../components/other/account/RecoverPassBox";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Footer from "../components/common/footer/Footer";
import ScrollTopBtn from "../components/common/ScrollTopBtn";
import breadcrumbimg from "../assets/images/login.jpg";
import { Link } from "react-router-dom";
import axios from "axios";
import sectiondata from "../store/store";
import { useState } from "react";
const state = {
  breadcrumbimg: breadcrumbimg,
};
function RecoverPassword() {
  const history = useHistory();
  const [email, setEmail] = useState("")
  const [newpass, setNewPass] = useState("")
  const [otp, setOtp] = useState("")


  const [disable, setDisable] = useState(false);
  const [succ, setSucc] = useState(false);
  const [err, seterr] = useState(false);
  const [errmesage, seterrmessage] = useState(false);
  const handlesubmit = (e) => {
    setDisable(true);
    e.preventDefault();
    axios
      .post(`${sectiondata.apiurl}/hindu/company/passChange`, { email: email, otp: otp, newPassword: newpass })
      .then((res) => {
        // console.log(res);
        setSucc(true);
        setTimeout(() => {
          setSucc(false);
          history.push("/login");
        }, 2000);
        setDisable(false);
      })
      .catch((err) => {
        // console.log(err?.response?.data?.
        //   message
        // );
        seterr(true);
        seterrmessage(err?.response?.data?.
          message ? err?.response?.data?.
          message : "Something Went Wraong Try Again");
        setTimeout(() => {
          seterr(false);
        }, 2000);
        setDisable(false);
      });
  };
  return (
    <main className="recover-pass-page">
      {/* Header */}
      <GeneralHeader />

      {/* Breadcrumb */}
      <Breadcrumb
        CurrentPgTitle="New Password"
        MenuPgTitle="Pages"
        img={state.breadcrumbimg}
      />

      {/* <RecoverPassBox /> */}
      <section className="form-shared padding-top-40px padding-bottom-100px">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 mx-auto">
              <div className="billing-form-item mb-0">

                <div className="billing-content">
                  <div className="contact-form-action">
                    <form onSubmit={handlesubmit}>
                      <div className="input-box">
                        <label className="label-text">Your Email</label>
                        <div className="form-group">
                          <input
                            value={email}
                            required
                            onChange={(e) => { setEmail(e.target.value) }}
                            style={{ paddingLeft: "20px" }}
                            className="form-control"
                            type="email"
                            name="text"
                            placeholder="Enter email address"
                          />
                        </div>
                      </div>
                      <div className="input-box">
                        <label className="label-text">OTP</label>
                        <div className="form-group">
                          <input
                            value={otp}
                            required
                            onChange={(e) => { setOtp(e.target.value) }}
                            style={{ paddingLeft: "20px" }}
                            className="form-control"
                            type="number"
                            name="text"
                            placeholder="Enter OTP"
                          />
                        </div>
                      </div>
                      <div className="input-box">
                        <label className="label-text">New Password</label>
                        <div className="form-group">
                          <input
                            minLength={6}
                            style={{ paddingLeft: "20px" }}
                            value={newpass}
                            required
                            onChange={(e) => { setNewPass(e.target.value) }}
                            className="form-control"
                            type="text"
                            name="text"
                            placeholder="Enter New Password"
                          />
                        </div>
                      </div>
                      <div className="btn-box margin-top-20px margin-bottom-20px">
                        <button
                          disabled={disable}
                          className="theme-btn border-0"
                          type="submit"
                        >
                          {disable ? "Please Wait..." : "reset now"}
                        </button>

                        {succ && (
                          <div class="alert alert-success" role="alert">
                            Password Updated Successfuly Please Wait !
                          </div>
                        )}
                        {err && (
                          <div class="alert alert-danger" role="alert">
                            {errmesage}
                          </div>
                        )}
                      </div>
                      <p className="font-weight-medium">
                        <Link to="/login" className="color-text">
                          Login{" "}
                        </Link>
                        or
                        <Link to="/sign-up" className="color-text">
                          {" "}
                          Register
                        </Link>
                      </p>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Newsletter */}
      {/* <NewsLetter newsLetterContent={sectiondata.calltoactions.newsletters} /> */}

      {/* Footer */}
      <Footer />

      <ScrollTopBtn />
    </main>
  );
}

export default RecoverPassword;
