import React from "react";
import { useState, useEffect } from "react";
import sectiondata from "../../store/store";
import axios from "axios";
import { useLocation } from "react-router-dom";
function Detail() {
  const location = useLocation();
  const [data, setData] = useState()
  const search = location.pathname.split("/")[2]
  const fetchData = async (k) => {

    // alert(0)
    try {
      const response = await axios.post(
        `${sectiondata.apiurl}/hindu/company/details`,
        { search: k }
      );
      // console.log(response.data.data[0], "res");
      setData(response.data.data[0]);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      //   setLoading(false);
    }


  };
  useEffect(() => {
    fetchData(search)
  }, [])
  return (
    <div>
      {" "}
      <div className="listing-description padding-top-40px padding-bottom-35px">
        <h2 className="widget-title">
          {/* {navigate.state.listingDetails.descriptiontitle} */}{"Description"}
        </h2>
        <div className="title-shape"></div>
        <div className="section-heading mt-4">
          <p className="sec__desc font-size-16">{data?.discription}</p>
        </div>
      </div>
    </div>
  );
}

export default Detail;
