import React from 'react';
// import Logo from "../Logo";
import SocialProfile from "../../other/account/SocialProfile";
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
function FooterLeftWidget({footerleftcnts}) {
    return (
        <>
            <div className="col-lg-3 column-td-6">
                <div className="footer-item">
                    <div className="logo">
                        <h1
                            class="elementor-heading-title elementor-size-large">HINDU BUSINESS DIRECTORY</h1>
                        {/* <p className="footer__desc"> */}
                          <Link to={footerleftcnts.lik}><p className="footer__desc"> {footerleftcnts.footerdesc}</p></Link> 
                        {/* </p> */}
                        <SocialProfile socials={footerleftcnts.sociallinks} />
                    </div>
                </div>
            </div>
        </>
    );
}

export default FooterLeftWidget;
