import React from 'react';
// import { GiPositionMarker } from 'react-icons/gi'
// import { FaRegEnvelope } from 'react-icons/fa'
// import { AiOutlineUser } from 'react-icons/ai'
// import { FiPhone, FiExternalLink } from 'react-icons/fi'
import imf from "../../../assets/images/usericon.jpeg"
import { useState,useEffect } from 'react';
import sectiondata from '../../../store/store';
import axios from 'axios';
// import Button from "../../common/Button";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
function WidgetAuthor({ contents }) {
    const location = useLocation();
    const search = location.pathname.split("/")[2]
    const [data, setData] = useState()
    const fetchData = async (k) => {
  
      // alert(0)
      try {
        const response = await axios.post(
          `${sectiondata.apiurl}/hindu/company/details`,
          { search: k }
        );
        // console.log(response.data.data[0], "res");
        setData(response.data.data[0]);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        //   setLoading(false);
      }
  
  
    };
    useEffect(() => {
      fetchData(search)
    }, [])
    return (
        <>
            <div className="sidebar-widget">
                <div className="author-bio margin-bottom-30px">
                    <div className="d-flex align-items-center">
                        {data?.logo ?
                            <img src={data?.logo} alt="author" />:
                            <img src={imf} alt="author" />}
                        <div className="author-inner-bio">
                            <h4 className="author__title font-weight-bold pb-0 mb-1">
                                {data?.firstName} {data?.lastName}
                            </h4>
                            <p className="author__meta">
                                {data?.category}
                            </p>
                        </div>
                    </div>
                </div>
                {/* <div className="info-list">
                    <ul>
                        <li className="mb-2">
                            <i className="la"><GiPositionMarker /></i> {contents.address}
                        </li>
                        <li className="mb-2">
                            <i className="la"><FaRegEnvelope /></i> <a href={'mailto:'+contents.email}>
                            {contents.email}
                        </a>
                        </li>
                        <li className="mb-2">
                            <i className="la"><FiPhone /></i> {contents.number}
                        </li>
                        <li className="mb-2">
                            <i className="la"><FiExternalLink /></i> <a href={contents.websiteUrl}>{contents.website}</a>
                        </li>
                    </ul>
                </div>
                <div className="section-block-2 margin-top-35px margin-bottom-35px"></div>
                <ul className="social-profile margin-bottom-35px text-center">

                    {contents.socials.map((item, index) => {
                        return (
                            <li key={index}>
                                <a href={item.url}>
                                    <i className="d-inline-block">
                                        {item.icon}
                                    </i>
                                </a>
                            </li>
                        )
                    })}
                </ul>
                <div className="btn-box text-center">
                    <Button text="view Profile" url="/user-profile" className="d-block">
                        <span className="d-inline-block"><AiOutlineUser /></span>
                    </Button>
                </div> */}
            </div>
        </>
    );
}

export default WidgetAuthor;
