import React from "react";
import GeneralHeader from "../components/common/GeneralHeader";
import Banner5 from "../components/banner/banner5/Banner5";
// import ImageBox from "../components/about/ImageBox";
// import About2 from "../components/about/About2";
// import FunFactsThree from "../components/other/funfacts/FunFactsThree";
// import { Link } from "react-router-dom";
// import { FiPlus } from "react-icons/fi";
import SectionsHeading from "../components/common/SectionsHeading";
// import Testimonial from "../components/sliders/Testimonial";
// import HowItWork4 from "../components/hiw/HowItWork4";
// import TeamOne from "../components/team/TeamOne";
import Button from "../components/common/Button";
// import NewsLetter from "../components/other/cta/NewsLetter";
import Footer from "../components/common/footer/Footer";
import ScrollTopBtn from "../components/common/ScrollTopBtn";
import sectiondata from "../store/store";

function About() {
  return (
    <main className="about-page">
      {/* Header */}
      <GeneralHeader />

      {/* Banner */}
      <Banner5 name={"World Hindu Economic Forum (HEF)"} />

      {/* <section className="blog-area padding-top-50px padding-bottom-80px before-none after-none">
                <div className="container">
                    <ImageBox items={sectiondata.aboutdata.imagebox} />
                </div>
            </section> */}

      <section className="about-area padding-bottom-100px">
        <div className="container">
          {/* <About2 aboutcontent={sectiondata.aboutdata.aboutst2} /> */}
          <div className="row">
            <div className="col-lg-12">
              <div className="about-content">
                <SectionsHeading
                  title={"World Hindu Economic Forum (HEF)"}
                  desc={
                    "Encourage other Hindu businesses to join Hindu Business Directory is Free Registration"
                  }
                  descClass=" font-size-17 pr-3 mb-3"
                >
                  <p className="sec__desc font-size-17 pr-3">
                    World Hindu Economic Forum is a platform that combines
                    various elements of Hindu Society, such as Industrialists,
                    Businesses, Traders, Bankers, Investors, Technocrats,
                    Professionals, Economists, Academicians and Thought Leaders
                    in self-organizing groups to enable their rapid growth and
                    success. <br/>
                    <br />
                    Each group can share their knowledge, experience, expertise
                    and resources with other members for mutual benefit. In
                    addition, HEF encourages members to support and mentor
                    budding Hindu entrepreneurs. The goal is to make all
                    sections of society to prosper.
                    <br /> <br/>
                    HEF believes that today Hindu Economic power has the
                    potential to re-emerge as a leading economic system. For
                    this, Hindu Society needs to participate in economic
                    opportunities through collaboration and cooperation.
                    <br /> <br/>
                    Our ancestors expressed their wisdom in the Vedas “Sat Hasta
                    Samahara, Sahastra Hasta Sankira”, which means “Create
                    Wealth with Hundred Hands and Share with Thousand Hands”.
                    Inspired by this wisdom, they created a just and harmonious
                    society which generated knowledge and prosperity. Hindus
                    created and contributed 33% of the world economy from the
                    1st to 17th century, creating equitable prosperity for all.
                    With a young population, it is an opportune time to recreate
                    Hindu prosperity.
                    <br /> <br/>
                    Hindus had an intertwined, self-organized social system that
                    permitted unrestricted growth of knowledge, development and
                    wealth. This model stands valid even today as self-organized
                    communities have grown faster and with greater resilience as
                    compared to communities where collaboration and co-operation
                    were hindered. Jews rebuilt themselves in the 20th century,
                    even after enduring the Holocaust, through networking,
                    community support and organisation. Today, the Jewish
                    community is an economic superpower of the world. Likewise,
                    Japan and Germany, after the devastation of the Second World
                    War, and global Chinese community in recent years, have all
                    emerged as economic powers through self-organization.
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      <br />
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      <br/>
                    Self-organization has always been a key catalyst in the
                    resurgence of communities and countries. HEF aims to provide
                    a framework for ‘local to global’ connectivity for
                    resources, opportunities, partnerships and know-how. This
                    will enable Hindus to rapidly contribute to 16% of the world
                    GDP which is commensurate to their proportion of the global
                    population. When we achieve such economic strength, we will
                    be able to fulfil the needs of our population and also
                    acquire respect in the global community.
                  </p>

                  {/* <div className="btn-box padding-top-30px">
                    <Button text="Register Your Business" url="#" />
                  </div> */}
                </SectionsHeading>
              </div>
            </div>
            {/* <div className="col-lg-6">
                    <div className="img-boxes">
                        <div className="row">
                            {sectiondata.aboutdata.aboutst2.items.map((item, i) => {
                                return (
                                    <div className="col-lg-6 column-td-6" key={i}>
                                        <div className={'img-box-item ' + item.boxClass}>
                                            <img src={item.img} alt="about" />
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div> */}
          </div>
        </div>
      </section>

      {/* Testimonial */}
      {/* <section className="testimonial-area padding-top-100px padding-bottom-100px text-center">
                {sectiondata.testimonialdata.tmimage.map((tmimg, index) => {
                    return (
                        <img key={index} src={tmimg.tmimg} alt="testimonial" className="random-img" />
                    )
                })}
                <div className="container">
                    <div className="row section-title-width text-center">
                        <SectionsHeading title={sectiondata.testimonialdata.sectitle} desc={sectiondata.testimonialdata.seccontent} />
                    </div>
                    <div className="row">
                        <div className="col-lg-8 mx-auto mt-4">
                            <Testimonial slideitems={sectiondata.testimonialdata.sliders} />
                        </div>
                    </div>
                </div>
            </section> */}

      {/* <section className="hiw-area padding-top-100px padding-bottom-90px after-none text-center section-bg">
                <div className="container">
                    <div className="row section-title-width text-center">
                        <SectionsHeading title={sectiondata.howitworks.hiw7.sectitle} desc={sectiondata.howitworks.hiw7.seccontent} />
                    </div>
                    <div className="row mt-5">
                        <HowItWork4 items={sectiondata.howitworks.hiw7.items} />
                    </div>
                </div>
            </section> */}

      {/* Team Area */}
      {/* <section className="team-area padding-top-100px padding-bottom-70px">
                <div className="container">
                    <div className="row section-title-width text-center">
                        <SectionsHeading title={sectiondata.teamdata.sectitle} desc={sectiondata.teamdata.seccontent} />
                    </div>

                    <div className="row mt-5">
                        <TeamOne teams={sectiondata.teamdata.teams} />
                    </div>
                </div>
            </section> */}

      {/* CTA */}
      <section className="cta-area padding-bottom-100px text-center">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="section-title-width text-center">
                <SectionsHeading
                  title={sectiondata.calltoactions.cta4.title}
                  desc={sectiondata.calltoactions.cta4.content}
                />
                <div className="btn-box mt-4 text-center">
                  <Button
                    text={sectiondata.calltoactions.cta4.btntext}
                    url={sectiondata.calltoactions.cta4.btnurl}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* NewsLetter */}
      {/* <NewsLetter newsLetterContent={sectiondata.calltoactions.newsletters} /> */}

      {/* Footer */}
      <Footer />

      <ScrollTopBtn />
    </main>
  );
}

export default About;
