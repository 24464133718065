import React from "react";
import GeneralHeader from "../../components/common/GeneralHeader";
import Breadcrumb from "../../components/common/Breadcrumb";
// import PopularCategories from "../../components/other/categories/PopularCategories";
// import NewsLetter from "../../components/other/cta/NewsLetter";
import Footer from "../../components/common/footer/Footer";
import PlaceOne from "../../components/places/PlaceOne";
import ScrollTopBtn from "../../components/common/ScrollTopBtn";
// import PopularCategoriesMore from "../../components/other/categories/PopularCategoriesMore";
import breadcrumbimg from "../../assets/images/login.jpg";
import sectiondata from "../../store/store";
import { useState, useEffect } from "react";
import axios from "axios";
import { useLocation } from "react-router-dom";
const state = {
    breadcrumbImg: breadcrumbimg,
};
function AllCategories() {
    // const [kewyword, setKeyWord] = useState("");
    // const [cate, setCat] = useState("");
    // const [sta, setSta] = useState("");
    const [done, setdone] = useState(false)
    const [cardData, setCardData] = useState([]);
    const location = useLocation();
    const fetchData = async (k, c, s) => {
        if (k && !s && !c) {
            // alert(0)
            try {
                const response = await axios.post(
                    `${sectiondata.apiurl}/hindu/company/details`,
                    { search: k }
                );
                // console.log(response, "res");
                setCardData(response.data.data);
            } catch (error) {
                console.error("Error fetching data:", error);
            } finally {
                setdone(true)
            }
        }
        if (!k && s && !c) {
            // alert(1)
            try {
                const response = await axios.post(
                    `${sectiondata.apiurl}/hindu/company/details`,
                    { state: s }
                );
                // console.log(response, "res");
                setCardData(response.data.data);
            } catch (error) {
                console.error("Error fetching data:", error);
            } finally {
                setdone(true)
            }
        }
        if (!k && !s && c) {
            // alert(2)
            try {
                const response = await axios.post(
                    `${sectiondata.apiurl}/hindu/company/details`,
                    { category: c }
                );
                // console.log(response, "res");
                setCardData(response.data.data);
            } catch (error) {
                console.error("Error fetching data:", error);
            } finally {
                setdone(true)
            }
        }
        if (k && s && !c) {
            // alert(3)
            try {
                const response = await axios.post(
                    `${sectiondata.apiurl}/hindu/company/details`,
                    { search: k, state: s }
                );
                // console.log(response, "res");
                setCardData(response.data.data);
            } catch (error) {
                console.error("Error fetching data:", error);
            } finally {
                setdone(true)
            }
        }
        if (k && !s && c) {
            // alert(4)
            try {
                const response = await axios.post(
                    `${sectiondata.apiurl}/hindu/company/details`,
                    { search: k, category: c }
                );
                // console.log(response, "res");
                setCardData(response.data.data);
            } catch (error) {
                console.error("Error fetching data:", error);
            } finally {
                //   setLoading(false);
                setdone(true)
            }
        }
        if (!k && s && c) {
            // alert(5)
            try {
                const response = await axios.post(
                    `${sectiondata.apiurl}/hindu/company/details`,
                    { category: c, state: s }
                );
                // console.log(response, "res");
                setCardData(response.data.data);
            } catch (error) {
                console.error("Error fetching data:", error);
            } finally {
                setdone(true)
            }
        }
        if (k && s && c) {
            // alert(6)
            try {
                const response = await axios.post(
                    `${sectiondata.apiurl}/hindu/company/details`,
                    { search: k, state: s, category: c }
                );
                // console.log(response, "res");
                setCardData(response.data.data);
            } catch (error) {
                console.error("Error fetching data:", error);
            } finally {
                setdone(true)
            }
        }
    };
    const srch = location.search;
    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const keyfromURl = params.get("keyword") || "";
        const compnameFromUrl = params.get("category") || "";
        const statefromurl = params.get("state") || "";
        // setKeyWord(keyfromURl);
        // setCat(compnameFromUrl);
        // setSta(statefromurl);
        // console.log(
        //     keyfromURl,
        //     compnameFromUrl,
        //     statefromurl,
        //     "thus",
        //     location,
        //     params
        // );
        fetchData(keyfromURl, compnameFromUrl, statefromurl);
    }, [srch]);

    return (
        <main className="all-categories">
            {/* Header */}
            <GeneralHeader />

            {/* Breadcrumb */}
            <Breadcrumb
                CurrentPgTitle="Search"
                MenuPgTitle="Categories"
                img={state.breadcrumbImg}
            />

            <section className="cat-area padding-top-40px padding-bottom-80px">
                <div className="container">
                    {
                        !done ?

                            <div
                                style={{ display: "grid", gap: "12px", flexWrap: "wrap" }}
                                className="display-flex specialone"
                            >
                                {[1, 2, 3].map((each) => {
                                    return (
                                        <div key={each} class="card-img skeleton2">
                                            <p> Loading..</p>
                                        </div>
                                    );
                                })}
                            </div> :
                            <div className="row">
                                {cardData.length === 0 && <h2>No Data Exist Try Again</h2>}
                                {cardData.length > 0 && <PlaceOne places={cardData} />}
                                {/* <PopularCategoriesMore catitems={sectiondata.popularcategories.morecats} /> */}
                            </div>}
                </div>
            </section>

            {/* NewsLetter */}
            {/* <NewsLetter newsLetterContent={sectiondata.calltoactions.newsletters} /> */}

            {/* Footer */}
            <Footer />

            <ScrollTopBtn />
        </main>
    );
}

export default AllCategories;
