import React from 'react';
import { GiPositionMarker } from 'react-icons/gi'
import { FaRegEnvelope } from 'react-icons/fa'
import { FiPhone, FiExternalLink } from 'react-icons/fi'
import { useState,useEffect } from 'react';
import axios from 'axios';
import sectiondata from '../../store/store';
import { useLocation } from "react-router-dom";
function ContactInfo({ contactinfos }) {
    const location = useLocation();
    const search = location.pathname.split("/")[2]
  
    const [data, setData] = useState()
    const fetchData = async (k) => {
  
      // alert(0)
      try {
        const response = await axios.post(
          `${sectiondata.apiurl}/hindu/company/details`,
          { search: k }
        );
        // console.log(response.data.data[0], "res");
        setData(response.data.data[0]);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        //   setLoading(false);
      }
  
  
    };
    useEffect(() => {
      fetchData(search)
    }, [])
    return (
        <>
            <div className="contact-listing padding-top-40px padding-bottom-40px">
                <h2 className="widget-title">
                    {contactinfos.title}
                </h2>
                <div className="title-shape"></div>
                <div className="info-list margin-top-35px padding-bottom-35px">
                    <ul>
                        {(data?.address || data?.state || data?.city || data?.country) ? (
                            <li className="mb-2"><span><i className="la d-inline-block"><GiPositionMarker /></i> Address:</span>
                                {data?.address} {data?.state} {location?.state.city}
                            </li>
                        ) : ''}
                        {data?.email ? (
                            <li className="mb-2"><span><i className="la d-inline-block"><FaRegEnvelope /></i> Email:</span>
                                <a href={'mailto:' + data.email}>{data.email}</a>
                            </li>
                        ) : ''}
                        {data?.contactNo ? (
                            <li className="mb-2"><span><i className="la d-inline-block"><FiPhone /></i> Phone:</span>
                                {data.contactNo}
                            </li>
                        ) : ''}
                        {data?.Landline
 ? (
                            <li className="mb-2"><span><i className="la d-inline-block"><FiPhone /></i> Land line
:</span>
                                {data.contactNo}
                            </li>
                        ) : ''}
                        {data?.websiteURL ? (
                            <li><span><i className="la d-inline-block"><FiExternalLink /></i> Website:</span>
                                <a href={data.websiteURL}>{"Website"}</a>
                            </li>
                        ) : ''}
                    </ul>
                </div>

                <div className="section-block"></div>
                <div className="social-contact padding-top-40px">
                    {data?.twitterURL && <a href={data?.twitterURL} className={'theme-btn twitter-link'}>
                        <i className="d-inline-block"></i> {"Twitter"}
                    </a>}
                    {data?.linkedinURL && <a href={data?.linkedinURL} className={'theme-btn linkedin-link'}>
                        <i className="d-inline-block"></i> {"Linkedin"}
                    </a>}
                    {data?.instagramURL && <a href={data?.instagramURL} className={'theme-btn instagram-link'}>
                        <i className="d-inline-block">{ }</i> {"Instagram"}
                    </a>}
                    {data?.facebookURL && <a href={data?.facebookURL} className={'theme-btn facebook-link'}>
                        <i className="d-inline-block">{ }</i> {"Facebook"}
                    </a>}

                    {/* {contactinfos.socials.map((item, i) => {
                        return (
                            <a key={i} href={item.url} className={'theme-btn '+item.title+'-link'}>
                                <i className="d-inline-block">{item.icon}</i> {item.title}
                            </a>
                        )
                    })} */}
                </div>
            </div>
        </>
    );
}

export default ContactInfo;
