import React from "react";
// import SignInOptions from "./SignInOptions";
import { FaRegEnvelope } from "react-icons/fa";
import { FiLock } from "react-icons/fi";
import { Link } from "react-router-dom";
import Cookies from "js-cookie";
import sectiondata from "../../../store/store";
import { useState } from "react";
import axios from "axios";
import { useHistory } from "react-router-dom";
function LoginBox({ title, subtitle }) {
  const apiUrl = sectiondata.apiurl;
  const navigate = useHistory();
  const [succ,setSucc]=useState(false)
  const [err,setErr]=useState(false)
  const [formData, setFormData] = useState({
    email: "",
    compPassword: "",
  });
  const [disable, setdisable] = useState(false);
  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };
  const handleSubmit = async (e) => {
    setdisable(true);
    e.preventDefault();
    // setError('');
    // setSuccess('');
    // setLoading(true); // Start loading animation

    try {
      const response = await axios.post(`${apiUrl}/hindu/company/Login`, {
        email: formData.email,
        compPassword: formData.compPassword,
      });
      // console.log(response);
      if (response.status === 200) {
        // console.log(response);
        Cookies.set("id", JSON.stringify(response.data.user._id));
        Cookies.set("Fname", JSON.stringify(response.data.user.firstName));
        Cookies.set("Lname", JSON.stringify(response.data.user.lastName));
        Cookies.set("token", response.data.token);
        Cookies.set("mobile", JSON.stringify(response.data.user.contactNo));
        Cookies.set("company", JSON.stringify(response.data.user.compName));
        Cookies.set("title", JSON.stringify(response.data.user.title));
        Cookies.set("emial", JSON.stringify(response.data.user.email));
        // Cookies.set("userData", JSON.stringify(response.data.user));
        // setSuccess('User registered successfully');
        setFormData({
          email: "",

          compPassword: "",
        });
        setSucc(true);
          setTimeout(() => {
            setSucc(false);
            navigate.push("/dashboard");
          }, 3000); // 5000 milliseconds = 5 seconds
       
     

        // navigate("/login");
        // Redirect to login popup
        // setShowLoginPopup(true);
      }
    } catch (error) {
      console.error("error", error);
      setErr(true);
      setTimeout(() => {
        setErr(false);
       
      }, 3000);
      // toast.success("error.response?.data?.message", {
      //     position: toast.POSITION.TOP_RIGHT,
      //   });
    } finally {
      setdisable(false); // Stop loading animation
    }
  };
  return (
    <>
      {succ && <div class="alert alert-success" role="alert">
          Login Successfuly Please Wait !
        </div>}
      {err && <div class="alert alert-danger" role="alert">
        Credentials Entered are wrong Try Again !
        </div>}
      
      <div className="billing-form-item mb-0">
        <div className="billing-title-wrap border-bottom-0 pr-0 pl-0 pb-0 text-center">
          <h3 className="widget-title font-size-28 pb-0">{title}</h3>
          {/* <p className="font-size-16 font-weight-medium">
                        {subtitle}
                    </p> */}
        </div>
        <div className="billing-content">
          <div className="contact-form-action">
            <form onSubmit={handleSubmit}>
              <div className="row">
                {/* <SignInOptions /> */}

                {/* <div className="col-lg-12">
                                    <div className="account-assist mt-4 mb-4 text-center">
                                        <p className="account__desc">or</p>
                                    </div>
                                </div> */}
                <div className="col-lg-12">
                  <div className="input-box">
                    <label className="label-text">email</label>
                    <div className="form-group">
                      <span className="form-icon">
                        <FaRegEnvelope />
                      </span>
                      <input
                        className="form-control"
                        required
                        type="email"
                        onChange={handleChange}
                        name="email"
                        value={formData.email}
                        placeholder="email"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="input-box">
                    <label className="label-text">Password</label>
                    <div className="form-group">
                      <span className="form-icon">
                        <FiLock />
                      </span>
                      <input
                        required
                        value={formData.compPassword}
                        onChange={handleChange}
                        className="form-control"
                        type="password"
                        name="compPassword"
                        placeholder="Password"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="form-group">
                    <div className="custom-checkbox mr-0 d-flex align-items-center justify-content-between">
                      {/* <div>
                                                <input type="checkbox" id="chb1" />
                                                <label htmlFor="chb1">Remember Me</label>
                                            </div> */}
                      <div>
                        <Link
                          to="/forgot"
                          className="color-text font-weight-medium"
                        >
                          Forgot password?
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="btn-box margin-top-20px margin-bottom-20px">
                    <button disabled={disable } className="theme-btn border-0" type="submit">
                      Login now
                    </button>
                  </div>
                </div>
                <div className="col-lg-12">
                  <p className="font-weight-medium">
                    Not a member?{" "}
                    <Link to="/sign-up" className="color-text">
                      {" "}
                      Register
                    </Link>
                  </p>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default LoginBox;
