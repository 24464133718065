import React from "react";
import { useState, useEffect } from "react";
import GeneralHeader from "../../components/common/GeneralHeader";
import Breadcrumb from "../../components/common/Breadcrumb";
// import { useDropzone } from "react-dropzone";
import { BsCloudUpload } from "react-icons/bs";
// import { Link } from "react-router-dom";
// import GeneralInfo from "../../components/addlisting/GeneralInfo";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
// import AddLocation from "../../components/addlisting/AddLocation";
import Cookies from "js-cookie";
import {
  AiOutlineUser,
  AiOutlineFacebook,
  AiOutlineTwitter,
  AiOutlineLinkedin,
} from "react-icons/ai";
import { FaRegEnvelope } from "react-icons/fa";
import { FiPhone } from "react-icons/fi";
import { BsLink45Deg, BsPencil } from "react-icons/bs";
// import { TiSocialGooglePlus } from "react-icons/ti";
import { FaBusinessTime } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
// import AddFullDetails from "../../components/addlisting/AddFullDetails";
// import PhotoUploader from "../../components/addlisting/PhotoUploader";
// import Amenities from "../../components/addlisting/Amenities";
// import OpeningHours from "../../components/addlisting/OpeningHours";
import { BsPencilSquare } from "react-icons/bs";
// import { AiOutlineTags } from "react-icons/ai";
import Select from "react-select";
// import { AiOutlineUser } from "react-icons/ai";
// import AddPrice from "../../components/addlisting/AddPrice";
// import NewsLetter from "../../components/other/cta/NewsLetter";
import Footer from "../../components/common/footer/Footer";
import ScrollTopBtn from "../../components/common/ScrollTopBtn";
// import { Link } from "react-router-dom";
import breadcrumbimg from "../../assets/images/login.jpg";
import sectiondata from "../../store/store";
import { FiMap } from "react-icons/fi";
import { FaMapSigns } from "react-icons/fa";
import { BsFileCode } from "react-icons/bs";
// import Button from "../../components/common/Button";
// import Select from "react-select";
import axios from "axios";
// import Cookies from "js-cookie";
// import SelectCountry from "../common/SelectCountry";
// const thumbsContainer = {
//   display: "flex",
//   flexDirection: "row",
//   flexWrap: "wrap",
//   marginTop: 16,
// };

// const thumb = {
//   display: "inline-flex",
//   borderRadius: 2,
//   border: "1px solid #eaeaea",
//   marginBottom: 0,
//   marginRight: 0,
//   width: "100%",
//   height: "auto",
//   padding: 4,
//   boxSizing: "border-box",
// };

// const thumbInner = {
//   display: "flex",
//   minWidth: 0,
//   overflow: "hidden",
//   alignItems: "start",
// };

const img = {
  display: "block",
  width: "auto",
  maxWidth: "808px",
  height: "auto",
};
const states = {
  breadcrumbimg: breadcrumbimg,
};
const servicesArray = [
  "Aboriginal Service",
  "Accomodation",
  "Accountants",
  "Adult Services",
  "Advertising Marketing",
  "Aged Care",
  "Air BnB",
  "Air Conditioning",
  "Alcohol & Bottle Wine Shops",
  "Alternative Health",
  "Antennas",
  "Antiques",
  "Appliance Rental",
  "Appliance Repairs",
  "Appliances White Goods store",
  "Aquarium Fish",
  "Aquatic",
  "Archery",
  "Architect Services",
  "Art & Decorators",
  "Art Studio Gallery Exhibitions",
  "Astrology Clairvoyant Psychic",
  "Ayurveda",
  "Auction",
  "Audio Services",
  "Auto Mechanic",
  "Babies clothes & supplies",
  "Bakers Bakery Cakes",
  "Balloon",
  "Banks & Financial Institutions",
  "Bathroom Renovations",
  "Batteries",
  "BBQ & Heating",
  "Beauty Salon & Hairdressers",
  "Bicycles",
  "Billards",
  "Bins",
  "Blinds & Shutters",
  "Boat Sales & Hire",
  "Bookkeeping Services",
  "Books",
  "Boot Camp",
  "Bowling Club",
  "Brewing Co",
  "Brick Layers",
  "Builders & Developers",
  "Building Contractors",
  "Building Materials",
  "Business Consulting",
  "Business Directory",
  "Business Services",
  "Butchers Meat",
  "Cafes & Restaurants",
  "Cake & Pastry Shops",
  "Camping",
  "Car & Motor Vehicle Finance",
  "Car Accessories",
  "Car Dealers",
  "Car Rental & Hire",
  "Car Wash",
  "Caravans",
  "Career Counselling",
  "Carpenters & Joiners",
  "Carpet Cleaning",
  "Carpets Rugs",
  "Cash Advance",
  "Casinos",
  "Catering Equipment",
  "Catering Services",
  "Chamber of Commerce",
  "Charitable & Non-Profit Organizations",
  "Chemical",
  "Chemist Pharmacy",
  "Child Care",
  "Church",
  "Cinema Movies",
  "Civil Works",
  "Cleaning Services",
  "Clothing Alterations Tailor",
  "Clothing Apparel",
  "Clothing Store",
  "Club",
  "Club Badminton",
  "Club Baseball",
  "Club Bowling",
  "Club Casinos & Gambling",
  "Club Football",
  "Club Golf",
  "Club Netball",
  "Club Poker",
  "Club RSL",
  "Club Soccer",
  "Club Sports",
  "Club Youth",
  "Clubs Car Racing",
  "Coaching Tutoring",
  "Community Centre",
  "Computer Software",
  "Computer Store",
  "Concreting",
  "Council",
  "Couriers",
  "Cruises",
  "Currency Exchange",
  "Cycles",
  "Dance & Music Coaching",
  "Dance Carnival & Magic",
  "Dating Services",
  "Day SPA",
  "Demolition Construction",
  "Dentist",
  "Distribution",
  "Doctors & Medical Clinics",
  "Dog Washing & Grooming",
  "Doors & Windows",
  "Driving School",
  "Drone",
  "Dry Cleaners",
  "Education Consultant",
  "Electrical & Plumbing",
  "Electrical Contractors",
  "Electrical Store",
  "Electricity Supplier",
  "Electricity Resellers",
  "Electronics Store",
  "Elevator Service",
  "Employment Agency",
  "Energy Brokers Electricity & Gas",
  "Engineering Consultants",
  "Entertainment",
  "Environment",
  "Event Management",
  "Fabricators",
  "Fabrics",
  "Farmers",
  "Fashion & Clothing",
  "Fencing",
  "Film Production Services",
  "Financial Consultants",
  "Fire Services",
  "Firewood",
  "Fireworks",
  "Fishing",
  "Flooring Carpets & Tiles",
  "Florist",
  "Food & Groceries",
  "Food Deliveries",
  "Foreign Exchange",
  "Forklifts",
  "Framing and Fine Art",
  "Function Centre",
  "Fundraising",
  "Funeral Services",
  "Furniture Store",
  "Gambling & Lottery Services",
  "Gaming Venues",
  "Garden Nurseries Plants & Turfs",
  "Glazier & Glass",
  "Government",
  "GPS",
  "Granny Flats",
  "Graphic Design Services",
  "Gym Health & Fitness Centres",
  "Gymnastic Clubs",
  "Hair & Beauty",
  "Hair Removal",
  "Hair salon",
  "Handyman",
  "Hardware Store",
  "Health & Beauty",
  "Health Clinic",
  "Health Supplements Vitamins",
  "Healthcare",
  "Hearing Aid",
  "Hobbies",
  "Home Improvement",
  "Hospital",
  "Hostel",
  "Hotel Club Supplies",
  "Hotels & Pubs",
  "Hotels Motels & Resorts",
  "Ice Cream",
  "Industrial Equipment",
  "Industrial Manufacturer",
  "Infrastructure Development",
  "Insurance Brokers & Consultants",
  "Interior Design Services",
  "Internet Marketing",
  "Internet Services",
  "Internet Shopping",
  "Ironing Services",
  "IT Services",
  "Jewellers",
  "Joinery",
  "Jumping Castles",
  "Karate",
  "Kids Activities",
  "Kids clothes & supplies",
  "Kids Sports",
  "Kitchen Manufacturers & Renovators",
  "Laser & Skin Clinic",
  "Laundry Service",
  "Lawyers & Solicitors",
  "Leisure Centre",
  "Lighting Suppliers",
  "Locks & Locksmiths",
  "Logistics Trucks",
  "LPG & Gas",
  "Magazines & Periodicals",
  "Make-Up Artists & Supplies",
  "Management Consultants",
  "Manchester",
  "Manufacturing & Industrial",
  "Marine",
  "Market Research",
  "Marketing Advertising",
  "Martial arts school",
  "Massage",
  "Mechanical & Industrial Services",
  "Media & Marketing",
  "Media Consultants",
  "Medical Centre",
  "Medical diagnostic imaging center",
  "Medical Supply",
  "Meditation",
  "Migration Services",
  "contactNo Phone",
  "Mobility Scooters",
  "Mortgage & Finance Brokers",
  "Motor Cycle Sales & Hire",
  "Music",
  "Nail salon",
  "Naturopaths",
  "Newspaper Publishing & Printing",
  "Office & Business Systems",
  "Office Furniture & Equipment",
  "Office Supplies",
  "OHS - Occupational Health & Safety",
  "Optometrist Eye Care",
  "Organic Products",
  "Packaging & Labelling Services",
  "Packaging Service",
  "Painters & Decorators",
  "Party Supplies",
  "Pawn Shop & Pawnbrokers",
  "Personal trainer",
  "Pest Control",
  "Pet Housing",
  "Pet Shops & Accessories",
  "Petcare",
  "Petrol",
  "Pharmacy & Chemist",
  "Phone Repairs",
  "Photographers",
  "Physiotherapist Chiropractic",
  "Physiotherapy & Chiro",
  "Piano",
  "Picture Framing",
  "Pizza Restaurants",
  "Plumbers",
  "Podiatry",
  "Political Organisation",
  "Post Office",
  "Printing Equipment Photocopier",
  "Printing Company",
  "Property Developers",
  "Property Values",
  "Psychologist Hypnotherapy",
  "Public Relations Services",
  "Publishing Magazines",
  "Quality Control & Safety Consultants",
  "Radio",
  "Real Estate",
  "Recruitment",
  "Recruitment Agency",
  "Recycling & Waste Management",
  "Rehabilitation Centre",
  "Religious Organisation",
  "Removalist",
  "Restaurant",
  "Retail Merchandising Service",
  "Retail Shop",
  "Retirement Villages",
  "Robot Manufacturing",
  "Roof Restoration",
  "Safes & Vaults",
  "Safety Equipments",
  "School College",
  "School Supplies",
  "Scrap Metal Merchants",
  "Seafood",
  "Security Services",
  "Security Surveillance Products & Services",
  "Self Defence",
  "Self Storage",
  "Senior Services",
  "Service Station",
  "Serviced Offices",
  "Sewing Machine",
  "Shipping Container",
  "Shoes",
  "Shopping Centre",
  "Signs",
  "Smash Repairs",
  "Social Services",
  "Solar",
  "Sport & Entertainment Associations",
  "Sporting Venues & Stadiums",
  "Sports Store",
  "Staffing & Support Services",
  "Storage",
  "Strata Management",
  "Student Accommodation",
  "Student Services",
  "Supermarket",
  "Surveyors",
  "Swimming Classes",
  "Swimming Pool & Spa",
  "Tattoo",
  "Taxi Uber Ola Ride Share",
  "Technological Research & Development",
  "Telecommunication",
  "Telephone Services",
  "Tow Trucks",
  "Tradie",
  "Training & Development",
  "Translators & Interpreters",
  "Transport",
  "Travel Agents & Consultants",
  "Tree Lopper",
  "Trophy Medals Plaques",
  "Tuition Coaching",
  "Tyres",
  "University & Colleges",
  "Vacuum Cleaners",
  "Vaults",
  "Veterinary Pet Health",
  "Visa Immigration Services",
  "Vitamin & Health Food",
  "Wardrobes",
  "Washing Machines & Dryer",
  "Waste Management Recycle Rubbish",
  "Waste Water Treatment",
  "Water Filters Aqua",
  "Web-Site Design SEO",
  "Wedding Planners",
  "Weddings Marriage Celebrant",
  "Weight Loss",
  "Wellness center",
  "White Goods Electronic Store",
  "Wholesalers & Manufacturers",
  "Window Cleaning",
  "Window Tinting",
  "Wineries",
  "Yoga",
  "Youth organization",
  "Youth services",
  "Zoo",
];
const newone = servicesArray.map((each) => {
  const a = { value: each, label: each };
  return a;
});

function AddListing() {
  const navigate = useHistory();
  const [files, setFiles] = useState([]);
  const [prelogo, setprelogo] = useState("");
  const [imagePreviews, setImagePreviews] = useState([]);
  // const { getRootProps, getInputProps } = useDropzone({
  //   accept: "image/*",
  //   onDrop: (acceptedFiles) => {
  //     setFiles(
  //       acceptedFiles.map((file) =>
  //         Object.assign(file, {
  //           preview: URL.createObjectURL(file),
  //         })
  //       )
  //     );
  //   },
  // });

  // const thumbs = files.map((file) => (
  //   <div style={thumb} key={file.name}>
  //     <div style={thumbInner}>
  //       <img src={file.preview} style={img} alt="Author Profile" />
  //     </div>
  //   </div>
  // ));

  useEffect(() => {
    axios.get(`${sectiondata.apiurl}/hindu/company/getUserInfo`, {
      headers: {
        Authorization: `Bearer ${Cookies.get("token")}`
      }
    }).then((res) => {
      // console.log(res.data.company, "this is data")
      const a = res.data.company;


    }).catch((err) => {
      console.log(err)
      Cookies.remove("id");
      Cookies.remove("Fname");
      Cookies.remove("Lname");
      Cookies.remove("token");
      Cookies.remove("mobile");
      Cookies.remove("company");
      Cookies.remove("emial");
      navigate.push("/login")
    })
  }, [])
  const handleChange = (e) => {
    const maxFileSize = 2 * 1024 * 1024;
    const { name, value, files } = e.target;
    // console.log(name, value, files, e.target.type);
    if (e.target.type === "file") {
      //   alert("entering file");
      if (name === "logo") {
        // alert("entering logo");
        const file = files[0];
        if (file.size > maxFileSize) {
          alert("Logo size should be less than 2MB.");
          return;
        }
        setFormData((prevData) => ({ ...prevData, [name]: file }));
        setprelogo([URL.createObjectURL(file)]);
        // console.log([URL.createObjectURL(file)]);
      }
      if (name === "image") {
        // alert("entering images");
        const validFiles = Array.from(files).filter((file) => {
          if (file.size > maxFileSize) {
            alert(`Image "${file.name}" exceeds the 2MB size limit.`);
            return false;
          }
          return true;
        });

        //   if (validFiles.length > 0) {
        //     setFormData(prevData => ({ ...prevData,    [name]: [...(prevData[name] || []), ...validFiles] }));
        //     setImagePreviews(validFiles.place_Map_url(file => URL.createObjectURL(file)));
        //   }
        if (validFiles.length > 0) {
          // alert("ryt");
          setFormData((prevData) => ({
            ...prevData,
            [name]: [...(prevData[name] || []), ...validFiles], // Append new files to the existing array
          }));
          setImagePreviews((prevPreviews) => [
            ...prevPreviews,
            ...validFiles.map((file) => URL.createObjectURL(file)), // Append new previews
          ]);
        }
      }
    } else {
      //   alert("no file");

      setFormData((prevData) => ({ ...prevData, [name]: value }));
    }
  };
  // const handleChange = (e) => {
  //     const maxFileSize = 2 * 1024 * 1024; // 2 MB
  //     const { name, files } = e.target;

  //     if (e.target.type === "file") {
  //       if (name === "logo") {
  //         const file = files[0]; // Get the first file for logo
  //         if (file) {
  //           if (file.size > maxFileSize) {
  //             alert("Logo size should be less than 2MB.");
  //             return;
  //           }
  //           setFormData(prevData => ({ ...prevData, [name]: file }));
  //           setprelogo([URL.createObjectURL(file)]); // Preview for logo
  //         }
  //       }

  //       if (name === "images") {
  //         const validFiles = Array.from(files).filter(file => {
  //           if (file.size > maxFileSize) {
  //             alert(`Image "${file.name}" exceeds the 2MB size limit.`);
  //             return false;
  //           }
  //           return true;
  //         });

  //         if (validFiles.length > 0) {
  //           setFormData(prevData => ({ ...prevData, [name]: validFiles }));
  //           setImagePreviews(validFiles.place_Map_url(file => URL.createObjectURL(file))); // Previews for images
  //         }
  //       }
  //     } else {
  //       setFormData(prevData => ({ ...prevData, [name]: e.target.value }));
  //     }
  //   };

  useEffect(
    () => () => {
      // Make sure to revoke the data uris to avoid memory leaks
      files.forEach((file) => URL.revokeObjectURL(file.preview));
    },
    [files]
  );
  const [formData, setFormData] = useState({
    city: "",
    firstName: Cookies.get("Fname")?.replaceAll(`"`, ""),
    title: Cookies.get("title")?.replaceAll(`"`, ""),
    lastName: Cookies.get("Lname")?.replaceAll(`"`, ""),
    place_Map_url: "",
    unit: "",
    compName: Cookies.get("company")?.replaceAll(`"`, ""),
    email: Cookies.get("emial")?.replaceAll(`"`, ""),
    contactNo: Cookies.get("mobile")?.replaceAll(`"`, ""),
    Landline: "",
    category: "",
    state: "",
    address: "",
    jobTitle: "",
    pincode: "",
    discription: "",
    place_place_Map_url_url: "",
    skypeURL: "",
    websiteURl: "",
    videoURL: "",
    facebookURL: "",
    linkedinURL: "",
    buisness: "",
    instagramURL: "",
    twitterURL: "",
    logo: null,
    image: [],
    agreedToTerms: true,
  });
  // console.log(formData, "formdata");
  const [loading, setLoading] = useState(false);
  const apiUrl = sectiondata.apiurl;

  const [secc, setSucc] = useState(false);
  const [err, setErr] = useState(false);
  const [re, setReq] = useState(false);

  const handlesubmit = async (e) => {
    e?.preventDefault();
    // alert("done");
    // console.log(!formData.discription,
    //   !formData.address,
    //   !formData.state,
    //   !formData.unit,
    //   !formData.city,
    //   !formData.category,
    //   !formData.pincode,
    //   !formData.jobTitle)
    if (
      !formData.discription ||
      !formData.address ||
      !formData.state ||
      !formData.unit ||
      !formData.city ||
      !formData.category ||
      !formData.pincode ||
      !formData.jobTitle
    ) {
      setReq(true);
      setTimeout(() => {
        setReq(false);
      }, 3000);
    } else {
      setLoading(true);

      const finalFormData = new FormData();

      for (const key in formData) {
        if (key === "image") {
          formData[key].forEach((file, index) => {
            finalFormData.append(`image`, file);
          });
        } else if (key === "logo" && formData[key]) {
          finalFormData.append("logo", formData[key]);
        } else {
          finalFormData.append(key, formData[key]);
        }
      }
      // console.log(finalFormData);
      try {
        const response = await axios.post(
          `${apiUrl}/hindu/company/compListing`,
          finalFormData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${Cookies.get("token")}`,
            },
          }
        );
        // alert("Form submitted successfully!");
        // Reset form or redirect user
        setFormData({
          firstName: "",
          title: "",
          lastName: "",
          place_Map_url: "",
          unit: "",
          compName: "",
          city: "",
          email: "",
          contactNo: "",
          Landline: "",
          category: "",
          state: "",
          address: "",
          jobTitle: "",
          pincode: "",
          discription: "",
          place_place_Map_url_url: "",
          skypeURL: "",
          websiteURl: "",
          videoURL: "",
          facebookURL: "",
          linkedinURL: "",
          buisness: "",
          instagramURL: "",
          twitterURL: "",
          logo: null,
          image: [],
          agreedToTerms: false,
        });
        setImagePreviews([]);
        setprelogo();
        setSucc(true);
        setTimeout(() => {
          setSucc(false);
          navigate.push("/dashboard");
        }, 3000);
      } catch (error) {
        console.error("Error submitting form:", error);
        // alert("Failed to submit form. Please try again.");
        setErr(true);
        setTimeout(() => {
          setErr(false);
        }, 3000);
      } finally {
        setLoading(false);
      }

      // console.log(formData, finalFormData);
    }
    //   try {
    //     const response = await axios.post(`${apiUrl}/hindu/company/compListing`, finalFormData, {
    //       headers: {
    //         'Content-Type': 'multipart/form-data',
    //         Authorization: `Bearer ${localStorage.getItem("token")}`
    //       }
    //     });
    //     alert('Form submitted successfully!');
    //     // Reset form or redirect user
    //     setFormData({
    //       firstName: '',
    //       lastName: '',
    //       compName: '',
    //       email: '',
    //       contactNo: '',
    //       category: '',
    //       state: '',
    //       address: '',
    //       pincode: '',
    //       discription: '',
    //       place_place_Map_url_url: '',
    //       skypeURL: '',
    //       websiteURl: '',
    //       videoURL: '',
    //       facebookURL: '',
    //       linkedinURL: '',
    //       instagramURL: '',
    //       twitterURL: '',
    //       logo: null,
    //       images: [],
    //       agreedToTerms: false
    //     });
    //     setImagePreviews([]);
    //     setLogoPreviews([]);
    //   } catch (error) {
    //     console.error('Error submitting form:', error);
    //     alert('Failed to submit form. Please try again.');
    //   } finally {
    //     setLoading(false);
    //   }
  };
  return (
    <main className="add-listing">
      {/* Header */}
      <GeneralHeader />

      {/* Breadcrumb */}
      <Breadcrumb
        CurrentPgTitle="Add Listing"
        MenuPgTitle="Listings"
        img={states.breadcrumbimg}
      />

      {/* Add Listing */}
      <section className="add-listing-area padding-top-40px padding-bottom-100px">
        <div className="container">
          <div className="row">
            <div className="col-lg-9 mx-auto">
              {/* <GeneralInfo /> */}
              <form onSubmit={handlesubmit}>
                <div>
                  <div className="billing-form-item">
                    <div className="billing-title-wrap">
                      <h3 className="widget-title pb-0">General Information</h3>
                      <div className="title-shape margin-top-10px"></div>
                    </div>

                    <div className="billing-content">
                      <div className="contact-form-action">
                        <div className="row">
                          <div className="col-lg-12">
                            {/* <div className="input-box">
                            <label className="label-text">Listing Title</label>
                            <div className="form-group">
                              <span className="la form-icon">
                                <BsPencilSquare />
                              </span>
                              <input
                                required
                                onChange={handleChange}
                                value={formData.title}
                                className="form-control"
                                type="text"
                                name="title"
                                placeholder="Enter your listing title"
                              />
                            </div>
                          </div> */}
                          </div>
                          <div className="col-lg-12">
                            <div className="input-box">
                              <label className="label-text">Company Name<span className="text-muted">(Required)</span></label>
                              <div className="form-group">
                                <span className="la form-icon">
                                  <BsPencilSquare />
                                </span>
                                <input
                                  required
                                  onChange={handleChange}
                                  disabled={true}
                                  style={{ cursor: "no-drop" }}
                                  value={formData.compName}
                                  className="form-control"
                                  type="text"
                                  name="compName"
                                  placeholder="Enter your company Name"
                                />
                              </div>
                            </div>
                          </div>

                          {/* <div className="col-lg-6">
                                    <div className="input-box">
                                        <label className="label-text">First Name</label>
                                        <div className="form-group">
                                            <span className="la form-icon">
                                                <AiOutlineUser />
                                            </span>
                                            <input className="form-control" type="text" name="name" placeholder="First Name" />
                                        </div>
                                    </div>
                                </div> */}
                          {/* <div className="col-lg-6">
                                    <div className="input-box">
                                        <label className="label-text d-flex align-items-center ">Last Name
                                        
                                        </label>
                                        <div className="form-group">
                                            <span className="la form-icon">
                                                <AiOutlineUser />
                                            </span>
                                            <input className="form-control" type="text" name="name" placeholder="Keywords should be separated by commas" />
                                        </div>
                                    </div>
                                </div> */}
                          <div className="col-lg-12">
                            <div className="input-box">
                              <label className="label-text">Description <span className="text-muted">(Required)</span> </label>
                              <div className="form-group">
                                <span className="la form-icon">
                                  <BsPencil />
                                </span>
                                <textarea
                                  // required
                                  value={formData.discription}
                                  onChange={handleChange}
                                  className="message-control form-control"
                                  name="discription"
                                  placeholder="Write your listing description"
                                ></textarea>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-12">
                            <div className="input-box">
                              <label className="label-text">Category <span className="text-muted">(Required)</span></label>
                              <div className="form-group mb-0">
                                <Select
                                  placeholder="Select a Category"
                                  //   value={formData.category}
                                  options={newone}
                                  onChange={(e) => {
                                    setFormData((prevData) => ({
                                      ...prevData,
                                      category: e.value,
                                    }));
                                  }}
                                  required
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="billing-form-item">
                    <div className="billing-title-wrap">
                      <h3 className="widget-title pb-0">Add Location</h3>
                      <div className="title-shape margin-top-10px"></div>
                    </div>
                    <div className="billing-content">
                      <div className="contact-form-action">
                        <form method="post">
                          <div className="row">
                            <div className="col-lg-6">
                              <div className="input-box">
                                <label className="label-text">Address <span className="text-muted">(Required)</span></label>
                                <div className="form-group">
                                  <span className="la form-icon">
                                    <FiMap />
                                  </span>
                                  <input
                                    className="form-control"
                                    type="text"
                                    onChange={handleChange}
                                    name="address"
                                    value={formData.address}
                                    placeholder="Your address"
                                    required
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <div className="input-box">
                                <label className="label-text">
                                  City
                                  <span className="text-muted">(Required)</span>
                                </label>
                                <div className="form-group">
                                  <span className="la form-icon">
                                    <FaMapSigns />
                                  </span>
                                  <input
                                    onChange={handleChange}
                                    className="form-control"
                                    value={formData.city}
                                    type="text"
                                    name="city"
                                    placeholder="City"
                                    required
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <div className="input-box">
                                <label className="label-text">
                                  Map Imbedded URL
                                  <span className="text-muted">(optional)</span>
                                </label>
                                <div className="form-group">
                                  <span className="la form-icon">
                                    <FiMap />
                                  </span>
                                  <input
                                    onChange={handleChange}
                                    className="form-control"
                                    value={formData.place_Map_url}
                                    type="text"
                                    name="place_Map_url"
                                    placeholder="Map Imbedded URL"
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <div className="input-box">
                                <label className="label-text">Unit  <span className="text-muted">(Required)</span></label>
                                <div className="form-group">
                                  <span className="la form-icon">
                                    <FaMapSigns />
                                  </span>
                                  <input
                                    required
                                    onChange={handleChange}
                                    className="form-control"
                                    value={formData.unit}
                                    type="text"
                                    name="unit"
                                    placeholder="Unit"
                                  />
                                </div>
                              </div>
                            </div>
                            {/* <div className="col-lg-6">
                                        <div className="input-box">
                                            <label className="label-text">City</label>
                                            <div className="form-group">
                                                <Select
                                                    value={this.selectedCity}
                                                    onChange={this.handleChangeCity}
                                                    placeholder="Select a City"
                                                    options={cities}
                                                />
                                            </div>
                                        </div>
                                    </div> */}
                            <div className="col-lg-6">
                              <div className="input-box">
                                <label className="label-text">State  <span className="text-muted">(Required)</span></label>
                                <div className="form-group">
                                  <Select
                                    // value={this.selectedState}
                                    // onChange={this.handleChangeState}
                                    placeholder="Select a State"
                                    //   value={formData.state}
                                    options={[
                                      {
                                        value: "ACT",
                                        label: "ACT",
                                      },
                                      {
                                        value: "NSW",
                                        label: "NSW",
                                      },
                                      {
                                        value: "NT",
                                        label: "NT",
                                      },
                                      {
                                        value: "QLD",
                                        label: "QLD",
                                      },
                                      {
                                        value: "SA",
                                        label: "SA",
                                      },
                                      {
                                        value: "TAS",
                                        label: "TAS",
                                      },

                                      {
                                        value: "VIC",
                                        label: "VIC",
                                      },
                                      {
                                        value: "WA",
                                        label: "WA",
                                      },
                                    ]}
                                    required
                                    onChange={(e) => {
                                      // alert(e.value);
                                      setFormData((prevData) => ({
                                        ...prevData,
                                        state: e.value,
                                      }));
                                    }}
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="col-lg-6">
                              <div className="input-box">
                                <label className="label-text">
                                  Postal Code  <span className="text-muted">(Required)</span>
                                </label>
                                <div className="form-group">
                                  <span className="la form-icon">
                                    <BsFileCode />
                                  </span>
                                  <input
                                    required
                                    onChange={handleChange}
                                    value={formData.pincode}
                                    className="form-control"
                                    type="text"
                                    name="pincode"
                                    placeholder="Postal Code"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                  {/* <AddFullDetails /> */}
                  <div className="billing-form-item">
                    <div className="billing-title-wrap">
                      <h3 className="widget-title pb-0">Full Details</h3>
                      <div className="title-shape margin-top-10px"></div>
                    </div>
                    <div className="billing-content">
                      <div className="contact-form-action">
                        <form method="post">
                          <div className="row">
                            <div className="col-lg-6">
                              <div className="input-box">
                                <label className="label-text">Job Title  <span className="text-muted">(Required)</span></label>
                                <div className="form-group">
                                  <span className="la form-icon">
                                    <AiOutlineUser />
                                  </span>
                                  <input
                                    required
                                    onChange={handleChange}
                                    value={formData.jobTitle}
                                    className="form-control"
                                    type="text"
                                    name="jobTitle"
                                    placeholder="Job Title"
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <div className="input-box">
                                <label className="label-text">Title  <span className="text-muted">(Required)</span></label>
                                <div className="form-group">
                                  <span className="la form-icon">
                                    <AiOutlineUser />
                                  </span>
                                  <input
                                    required
                                    disabled={true}
                                    style={{ cursor: "no-drop" }}
                                    onChange={handleChange}
                                    value={formData.title}
                                    className="form-control"
                                    type="text"
                                    name="title"
                                    placeholder=" Title"
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <div className="input-box">
                                <label className="label-text">
                                  Owner First Name  <span className="text-muted">(Required)</span>
                                </label>
                                <div className="form-group">
                                  <span className="la form-icon">
                                    <AiOutlineUser />
                                  </span>
                                  <input
                                    required
                                    className="form-control"
                                    onChange={handleChange}
                                    type="text"
                                    value={formData.firstName}
                                    name="firstName"
                                    disabled={true}
                                    style={{ cursor: "no-drop" }}
                                    placeholder="Owner First Name"
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <div className="input-box">
                                <label className="label-text">
                                  Owner Last Name  <span className="text-muted">(Required)</span>
                                </label>
                                <div className="form-group">
                                  <span className="la form-icon">
                                    <AiOutlineUser />
                                  </span>
                                  <input
                                    required
                                    disabled={true}
                                    style={{ cursor: "no-drop" }}
                                    className="form-control"
                                    onChange={handleChange}
                                    type="text"
                                    value={formData.lastName}
                                    name="lastName"
                                    placeholder="Owaner Last Name"
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <div className="input-box">
                                <label className="label-text">Email  <span className="text-muted">(Required)</span></label>
                                <div className="form-group">
                                  <span className="la form-icon">
                                    <FaRegEnvelope />
                                  </span>
                                  <input
                                    required
                                    disabled={true}
                                    style={{ cursor: "no-drop" }}
                                    onChange={handleChange}
                                    value={formData.email}
                                    className="form-control"
                                    type="email"
                                    name="email"
                                    placeholder="Email address"
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <div className="input-box">
                                <label className="label-text">Phone  <span className="text-muted">(Required)</span> </label>
                                <div className="form-group">
                                  <span className="la form-icon">
                                    <FiPhone />
                                  </span>
                                  <input
                                    required
                                    disabled={true}
                                    style={{ cursor: "no-drop" }}
                                    onChange={handleChange}
                                    value={formData.contactNo}
                                    className="form-control"
                                    type="number"
                                    name="contactNo"
                                    placeholder="Number"
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <div className="input-box">
                                <label className="label-text">
                                  Landlline{" "}
                                  <span className="text-muted">(optional)</span>
                                </label>
                                <div className="form-group">
                                  <span className="la form-icon">
                                    <FiPhone />
                                  </span>
                                  <input
                                    value={formData.Landline}
                                    className="form-control"
                                    type="number"
                                    onChange={handleChange}
                                    name="Landline"
                                    placeholder="Landline Number"
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <div className="input-box">
                                <label className="label-text">
                                  Website{" "}
                                  <span className="text-muted">(optional)</span>
                                </label>
                                <div className="form-group">
                                  <span className="la form-icon">
                                    <BsLink45Deg />
                                  </span>
                                  <input
                                    onChange={handleChange}
                                    value={formData.websiteURl}
                                    className="form-control"
                                    type="text"
                                    name="websiteURl"
                                    placeholder="https://techydevs.com"
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <div className="input-box">
                                <label className="label-text">
                                  Facebook Link{" "}
                                  <span className="text-muted">(optional)</span>
                                </label>
                                <div className="form-group">
                                  <span className="la form-icon">
                                    <AiOutlineFacebook />
                                  </span>
                                  <input
                                    onChange={handleChange}
                                    value={formData.facebookURL}
                                    className="form-control"
                                    type="text"
                                    name="facebookURL"
                                    placeholder="https://www.facebook.com/"
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <div className="input-box">
                                <label className="label-text">
                                  Twitter Link{" "}
                                  <span className="text-muted">(optional)</span>
                                </label>
                                <div className="form-group">
                                  <span className="la form-icon">
                                    <AiOutlineTwitter />
                                  </span>
                                  <input
                                    onChange={handleChange}
                                    value={formData.twitterURL}
                                    className="form-control"
                                    type="text"
                                    name="twitterURL"
                                    placeholder="https://www.twitter.com/"
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <div className="input-box">
                                <label className="label-text">
                                  Instagram{" "}
                                  <span className="text-muted">(optional)</span>
                                </label>
                                <div className="form-group">
                                  <span className="la form-icon">
                                    <FaInstagram />
                                  </span>
                                  <input
                                    className="form-control"
                                    type="text"
                                    onChange={handleChange}
                                    name="instagramURL"
                                    value={formData.instagramURL}
                                    placeholder="https://instagram.com"
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <div className="input-box">
                                <label className="label-text">
                                  linkedinURL Link{" "}
                                  <span className="text-muted">(optional)</span>
                                </label>
                                <div className="form-group">
                                  <span className="la form-icon">
                                    <AiOutlineLinkedin />
                                  </span>
                                  <input
                                    onChange={handleChange}
                                    className="form-control"
                                    value={formData.linkedinURL}
                                    type="text"
                                    name="linkedinURL"
                                    placeholder="https://linkedin.com"
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <div className="input-box">
                                <label className="label-text">
                                  Bussiness{" "}
                                  <span className="text-muted">(optional)</span>
                                </label>
                                <div className="form-group">
                                  <span className="la form-icon">
                                    <FaBusinessTime />
                                  </span>
                                  <input
                                    className="form-control"
                                    onChange={handleChange}
                                    type="text"
                                    name="buisness"
                                    value={formData.buisness}
                                    placeholder="https://linkedinURL.com"
                                  />
                                </div>
                              </div>
                            </div>
                            {/* <div className="col-lg-12">
                                    <div className="input-box">
                                        <label className="label-text">Description</label>
                                        <div className="form-group mb-0">
                                            <span className="la form-icon">
                                                <BsPencil />
                                            </span>
                                            <textarea className="message-control form-control" name="message" placeholder="Write description"></textarea>
                                        </div>
                                    </div>
                                </div> */}
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                  <p>To upload logos and images, first create and then edit your listing.</p>
                  {/* <PhotoUploader /> */}
                  {/* <div className="billing-form-item"> */}
                  {/* <div className="billing-title-wrap">
                      <h3
                        className="widget-title pb-0"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <span>Logo</span>{" "}
                        {prelogo && (
                          <img
                            style={{
                              height: "120px",
                              width: "120px",
                              borderRadius: "100%",
                            }}
                            alt={"logo"}
                            src={prelogo}
                          />
                        )}
                      </h3>
                      <div className="title-shape margin-top-10px"></div>
                    </div> */}

                  {/* <div className="billing-content">
                      <div className="row">
                        <div className="col-lg-12">
                          <div
                            style={{ cursor: "pointer" }}
                            className="drag-and-drop-wrap text-center "
                            onClick={() => {
                              document.getElementById("logo").click();
                            }}
                          >
                            <div className="drag-and-drop-file">
                              <div>
                                <input
                                  onChange={handleChange}
                                  name="logo"
                                  type="file"
                                  id="logo"
                                  style={{ display: "none" }}
                                />
                                <span className="drag-drop-icon">
                                  <BsCloudUpload />
                                </span>
                                <h3>Drag & Drop Files Here to Upload</h3>
                              </div>
                              <aside style={thumbsContainer}>{thumbs}</aside>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div> */}

                  {/* </div> */}
                  {/* <div className="billing-form-item">
                    <div className="billing-title-wrap">
                      <h3
                        className="widget-title pb-0"
                        style={{
                          overflow: "auto",
                          gap: "5px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <span>Images</span>{" "}
                        {imagePreviews.length > 0 &&
                          imagePreviews.map((e) => {
                            return (
                              <img
                                style={{
                                  height: "120px",
                                  width: "120px",
                                  borderRadius: "100%",
                                }}
                                alt={"logo"}
                                src={e}
                              />
                            );
                          })}
                      </h3>
                      <div className="title-shape margin-top-10px"></div>
                    </div>
                    <div className="billing-content">
                      <div className="row">
                        <div className="col-lg-12">
                          <div
                            style={{ cursor: "pointer" }}
                            className="drag-and-drop-wrap text-center "
                            onClick={() => {
                              if (imagePreviews.length !== 4) {
                                document.getElementById("images").click();
                              }
                            }}
                          >
                            <div className="drag-and-drop-file">
                              <div>
                                <input
                                  onChange={handleChange}
                                  name="image"
                                  type="file"
                                  id="images"
                                  style={{ display: "none" }}
                                />
                                <span className="drag-drop-icon">
                                  <BsCloudUpload />
                                </span>
                                <h3>To Upload a banner images first create Listing</h3>
                              </div>
                              <aside style={thumbsContainer}>{thumbs}</aside>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div> */}
                </div>
                <button
                  disabled={loading}
                  className="theme-btn"
                  style={{ outline: "none", border: "none" }}
                  type="submit"
                >
                  {loading ? "Creating..." : "Create Now"}
                </button>
                <div></div>

                <div>
                  {secc && (
                    <div
                      class="alert alert-success "
                      style={{ margin: "auto" }}
                      role="alert"
                    >
                      Listing Created Successfuly Please Wait !
                    </div>
                  )}
                  {err && (
                    <div
                      class="alert alert-danger "
                      style={{ margin: "auto" }}
                      role="alert"
                    >
                      Something Went Wrong Try Again
                    </div>
                  )}
                  {re && (
                    <div
                      class="alert alert-danger "
                      style={{ margin: "auto" }}
                      role="alert"
                    >
                      Some Required Fields Are not Filled
                    </div>
                  )}
                </div>
              </form>
              {/* <Amenities /> */}

              {/* <OpeningHours /> */}

              {/* <AddPrice /> */}

              {/* <div className="billing-form-item p-0 border-0 mb-0 shadow-none">
                                <div className="billing-content p-0">
                                    <div className="custom-checkbox d-block mr-0">
                                        <input type="checkbox" id="privacy" />
                                        <label htmlFor="privacy">I Agree to Dirto's <Link to="#" className="color-text">Privacy Policy</Link></label>
                                    </div>
                                    <div className="custom-checkbox d-block mr-0">
                                        <input type="checkbox" id="terms" />
                                        <label htmlFor="terms">I Agree to Dirto's <Link to="#" className="color-text">Terms of Services</Link>
                                        </label>
                                    </div>
                                    <div className="btn-box mt-4">
                                        <button type="submit" className="theme-btn border-0">submit listing</button>
                                    </div>
                                </div> */}
              {/* </div> */}
            </div>
          </div>
        </div>
      </section>

      {/* Newsletter */}
      {/* <NewsLetter newsLetterContent={sectiondata.calltoactions.newsletters} /> */}

      {/* Footer */}
      <Footer />

      <ScrollTopBtn />
    </main>
  );
}

export default AddListing;
