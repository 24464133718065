import React from "react";
import { Link } from "react-router-dom";
import { FaRegEnvelope } from "react-icons/fa";
import { useState } from "react";
import axios from "axios";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import sectiondata from "../../../store/store";

function RecoverPassBox() {
    const history = useHistory();

    const [email, setEmail] = useState("");
    const [disable, setDisable] = useState(false);
    const [succ, setSucc] = useState(false);
    const [err, seterr] = useState(false);
    const [errmesage, seterrmessage] = useState(false);
    const handlesubmit = (e) => {
        setDisable(true);
        e.preventDefault();
        axios
            .post(`${sectiondata.apiurl}/hindu/company/forgotPass`, { email: email })
            .then((res) => {
                // console.log(res);
                setSucc(true);
                setTimeout(() => {
                    setSucc(false);
                    history.push("/set-new-password");
                }, 2000);
                setDisable(false);
            })
            .catch((err) => {
                // console.log(err?.response?.data?.
                //     message
                // );
                seterr(true);
                seterrmessage(err?.response?.data?.
                    message ? err?.response?.data?.
                    message : "Something Went Wraong Try Again");
                setTimeout(() => {
                    seterr(false);
                }, 2000);
                setDisable(false);
            });
    };
    return (
        <>
            <section className="form-shared padding-top-40px padding-bottom-100px">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 mx-auto">
                            <div className="billing-form-item mb-0">
                                <div className="billing-title-wrap">
                                    <h3 className="widget-title font-size-28">
                                        Recover Password!
                                    </h3>
                                    <p className="font-size-16 font-weight-medium">
                                        Enter the email of your account to reset password. Then you
                                        will receive a OTP to email to reset the password.
                                    </p>
                                </div>
                                <div className="billing-content">
                                    <div className="contact-form-action">
                                        <form onSubmit={handlesubmit}>
                                            <div className="input-box">
                                                <label className="label-text">Your Email</label>
                                                <div className="form-group">
                                                    <span className="la form-icon">
                                                        <FaRegEnvelope />
                                                    </span>
                                                    <input
                                                        required
                                                        value={email}
                                                        onChange={(e) => {
                                                            setEmail(e.target.value);
                                                        }}
                                                        className="form-control"
                                                        type="email"
                                                        name="text"
                                                        placeholder="Enter email address"
                                                    />
                                                </div>
                                            </div>
                                            <div className="btn-box margin-top-20px margin-bottom-20px">
                                                <button
                                                    disabled={disable}
                                                    className="theme-btn border-0"
                                                    type="submit"
                                                >
                                                    {disable ? "Sending OTP ..." : " reset password"}
                                                </button>
                                            </div>

                                            {succ && (
                                                <div class="alert alert-success" role="alert">
                                                    OTP Sended Successfuly Please Wait !
                                                </div>
                                            )}
                                            {err && (
                                                <div class="alert alert-danger" role="alert">
                                                    {errmesage}
                                                </div>
                                            )}
                                            <p className="font-weight-medium">
                                                <Link to="/login" className="color-text">
                                                    Login{" "}
                                                </Link>
                                                or
                                                <Link to="/sign-up" className="color-text">
                                                    {" "}
                                                    Register
                                                </Link>
                                            </p>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default RecoverPassBox;
