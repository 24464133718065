// import React from "react"
// import markericon from '../../assets/images/map-marker.png'

// const { compose, withProps } = require("recompose");
// const {
//     withScriptjs,
//     withGoogleMap,
//     GoogleMap,
//     BicyclingLayer,
//     Marker
// } = require("react-google-maps");
// const GeneralMap = compose(
//     withProps({
//         googleMapURL: "https://maps.googleapis.com/maps/api/js?key=AIzaSyAYzby4yYDVaXPmtu4jZAGR258K6IYwjIY&libraries",
//         loadingElement: <div style={{ height: `100%` }} />,
//         containerElement: <div style={{ height: `500px` }} />,
//         mapElement: <div style={{ height: `100%` }} />,
//     }),
//     withScriptjs,
//     withGoogleMap
// )(props =>
//     <GoogleMap
//         defaultZoom={13}
//         defaultCenter={{ lat: 40.712776, lng: -74.005974 }}
//         defaultOptions={{
//             disableDefaultUI: false, // disable default map UI
//             draggable: true, // make map draggable
//             keyboardShortcuts: false, // disable keyboard shortcuts
//             scaleControl: true, // allow scale control
//             scrollwheel: true, // allow scroll wheel
//             styles: [
//                 {
//                     "featureType": "road",
//                     "stylers": [
//                         { "color": "#ffffff" }
//                     ]
//                 }, {
//                     "featureType": "water",
//                     "stylers": [
//                         { "color": "#e9e9e9" }
//                     ]
//                 }, {
//                     "featureType": "landscape",
//                     "stylers": [
//                         { "color": "#f5f5f5" }
//                     ]
//                 }, {
//                     "elementType": "labels.text.fill",
//                     "stylers": [
//                         { "color": "transparent" }
//                     ]
//                 }, {
//                     "featureType": "poi",
//                     "stylers": [
//                         { "color": "#fefefe" }
//                     ]
//                 }, {
//                     "elementType": "labels.text",
//                     "stylers": [
//                         { "saturation": 1 },
//                         { "weight": 0.1 },
//                         { "color": "#737980" }
//                     ]
//                 }
//             ],
//             icon: markericon
//         }}
//     >
//         <Marker
//             icon={{
//                 url: markericon
//             }}
//             animation={1}

//             position={{
//                 lat: 40.712776, // latitude to position the marker
//                 lng: -74.005974 // longitude to position the marker
//             }}
//         />
//         <BicyclingLayer autoUpdate />
//     </GoogleMap>
// );

// export default GeneralMap
import React from 'react'
import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import axios from 'axios';
import sectiondata from '../../store/store';


const GeneralMap = () => {
    const location = useLocation();
    // console.log(location);
    const search = location.pathname.split("/")[2]
    // console.log(search)
    const [data, setData] = useState()
    const fetchData = async (k) => {

        // alert(0)
        try {
            const response = await axios.post(
                `${sectiondata.apiurl}/hindu/company/details`,
                { search: k }
            );
            // console.log(response.data.data[0], "res");
            setData(response.data.data[0]);
        } catch (error) {
            console.error("Error fetching data:", error);
        } finally {
            //   setLoading(false);
        }


    };
    useEffect(() => {
        fetchData(search)
    }, [])
    return (
        <div style={{ overflow: "hidden" }}><div dangerouslySetInnerHTML={{
            __html: data?.place_Map_url
        }}></div></div>
    )
}

export default GeneralMap