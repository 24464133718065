import React from 'react'
import GeneralHeader from '../../components/common/GeneralHeader'
import BannerOne from '../../components/banner/banner1/BannerOne'
import Testimonial from "../../components/sliders/Testimonial";
// import ClientLogo from "../../components/sliders/ClientLogo";
// import NewsLetter from "../../components/other/cta/NewsLetter";
// import ImageBox from "../../components/about/ImageBox";
import About2 from "../../components/about/About2";
import cat from "../../assets/images/category.jfif";
import { Link } from "react-router-dom";
import Footer from "../../components/common/footer/Footer";
import ScrollTopBtn from "../../components/common/ScrollTopBtn";
import SectionsHeading from "../../components/common/SectionsHeading";
// import PopularCategories from "../../components/other/categories/PopularCategories";
// import HowItWorkOne from "../../components/hiw/hiw1/HowItWorkOne";
// import FunFactsOne from "../../components/other/funfacts/funfacts1/FunFactsOne";
// import CtaOne from "../../components/other/cta/CtaOne";
// import LatestBlog from "../../components/blogs/LatestBlog";
import Button from "../../components/common/Button";
import SectionDivider from "../../components/common/SectionDivider";
// import InfoBox3 from "../../components/other/infoboxes/InfoBox3";
import PlaceOne from "../../components/places/PlaceOne";
import sectiondata from "../../store/store";
import { useState, useEffect, } from 'react';
import axios from 'axios';
function Home() {
    const [data, setData] = useState()
    const [data3, setData3] = useState([]);
    useEffect(() => {
        axios
            .get(`${sectiondata.apiurl}/hindu/company/getAllCategories`)
            .then((res) => {
                // console.log(res.data.data);
                const a = res.data.data
                const b = a.filter((e, i) => { return i < 4 })
                // console.log(b)

                setData3(b);
            })
            .catch((err) => {
                console.log(err);
            });
    }, []);
    useEffect(() => {
        axios.get(`${sectiondata.apiurl}/hindu/company/active/compDetails`).then((res) => {
            // console.log(res.data.data)
            const a = res.data.data
            const b = a.filter((e, i) => { return i < 5 })
            // console.log(b)
            setData(b)
        }).catch((err) => {
            console.log(err)

        })
    }, [])
    return (
        <main className="home-1">
            {/* Header */}
            <GeneralHeader />

            {/* Hero Banner */}

            <BannerOne />
            <section className="about-area padding-top-100px  vedio-margin-adust">
                <div className="container">
                    <About2 aboutcontent={sectiondata.aboutdata.aboutst2} />
                </div>
            </section>
            {/* Popular Categories */}
            <section className="cat-area padding-top-100px padding-bottom-90px">
                <div className="container">
                    <div className="row section-title-width text-center">
                        <SectionsHeading title={sectiondata.popularcategories.sectitle} />
                    </div>
                    <div className="row mt-5">
                        {data3.map((each, index) => {
                            if (each.categoryName) {
                                return (
                                    <div className="col-lg-3 column-td-6" key={index}>
                                        <div className="category-item mb-4 mt-0 ml-0 mr-0 p-0">
                                            <figure className="category-fig m-0">
                                                <img src={cat} alt="category" className="cat-img" />
                                                <figcaption className="fig-caption">
                                                    <Link
                                                        to={`/search?category=${each?.categoryName}`}
                                                        className="cat-fig-box"
                                                    >
                                                        <div className="icon-element mb-3">{each.count}</div>
                                                        <div className="cat-content">
                                                            <h4 className="cat__title mb-3">
                                                                {each?.categoryName}
                                                            </h4>
                                                            <span className="badge">{each?.categoryName}</span>
                                                        </div>
                                                    </Link>
                                                </figcaption>
                                            </figure>
                                        </div>
                                    </div>
                                );
                            }

                        })}
                    </div>
                </div>
            </section>

            {/* How It Work */}
            {/* <section className="hiw-area text-center padding-top-100px padding-bottom-110px">
                <div className="container">
                    <div className="row section-title-width text-center">
                        <SectionsHeading title={sectiondata.howitworks.hiw1.sectitle} desc={sectiondata.howitworks.hiw1.seccontent} />
                    </div>

                    <HowItWorkOne hiw1content={sectiondata.howitworks.hiw1} />
                </div>
            </section> */}

            {/* Most Visited Place */}
            <section className="card-area text-center padding-bottom-100px">
                <div className="container">
                    <div className="row section-title-width text-center">
                        <SectionsHeading title={sectiondata.mostvisitedplaces.sectitle} />
                    </div>
                    {data &&
                        <PlaceOne pop={true} places={data} />}
                </div>
            </section>

            {/* FunFacts */}
            {/* <section className="funfact-area section-bg-2 padding-top-100px padding-bottom-50px text-center">
                <div className="container">
                    <div className="row section-title-width">
                        <SectionsHeading title={sectiondata.funfacts.funfact1.sectitle} titleClass="text-white" desc={sectiondata.funfacts.funfact1.seccontent} />
                    </div>

                    <FunFactsOne countitems={sectiondata.funfacts.funfact1.counteritems} />
                </div>
            </section> */}

            {/* How It Work */}
            {/* <section className="hiw-area padding-top-100px padding-bottom-80px after-none text-center">
                <div className="container">
                    <div className="row section-title-width text-center">
                        <SectionsHeading title={sectiondata.howitworks.hiw2.sectitle} desc={sectiondata.howitworks.hiw2.seccontent} />
                    </div>

                    <InfoBox3 infoitems={sectiondata.howitworks.hiw2.items} />
                </div>
            </section> */}

            {/* CTA */}
            <section className="cta-area section-bg column-sm-center padding-top-80px padding-bottom-80px">
                {sectiondata.calltoactions.cta1.shapes.map((img, index) => {
                    return (
                        <img src={img.img} key={index} alt="Cta Symble" className="symble-img" />
                    )
                })}
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-9 text-left">
                            <SectionsHeading title={sectiondata.calltoactions.cta1.title} titleClass=" mb-3 font-size-28" descClass=" font-size-17" desc={sectiondata.calltoactions.cta1.content} />
                        </div>

                        <div className="col-lg-3">
                            <div className="btn-box">
                                <Button text={sectiondata.calltoactions.cta1.btntext} url={sectiondata.calltoactions.cta1.btnurl} />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* Testimonial */}
            <section className="testimonial-area padding-top-100px padding-bottom-100px text-center">
                {/* {sectiondata.testimonialdata.tmimage.map((tmimg, index) => {
                    return (
                        <img key={index} src={tmimg.tmimg} alt="testimonial" className="random-img" />
                    )
                })} */}
                <div className="container">
                    <div className="row section-title-width text-center">
                        <SectionsHeading title={"Our Top Clients"} desc={"What our best clients are saying about us"} />
                    </div>
                    <div className="row">
                        <div className="col-lg-8 mx-auto mt-4">
                            <Testimonial slideitems={sectiondata.testimonialdata.sliders} />
                        </div>
                    </div>
                </div>
            </section>

            <SectionDivider />



            {/* Blog */}
            {/* <section className="blog-area padding-top-100px padding-bottom-80px">
                <div className="container">
                    <div className="row section-title-width section-title-ml-mr-0">
                        <div className="col-lg-8">
                            <SectionsHeading title={sectiondata.latestarticles.sectitle} desc={sectiondata.latestarticles.seccontent} />
                        </div>
                        <div className="col-lg-4">
                            <div className="btn-box h-100 d-flex align-items-center justify-content-end">
                                <Button text={sectiondata.latestarticles.btntext} url={sectiondata.latestarticles.btnurl} className=" margin-top-100px" />
                            </div>
                        </div>
                    </div>

                    <LatestBlog latestarticles={sectiondata.latestarticles.items} />
                </div>
            </section> */}

            {/* CTA 2 */}
            {/* <section className="cta-area cta-area3 padding-top-100px padding-bottom-100px section-bg">
                <CtaOne />
            </section> */}

            {/* Client Logo */}
            {/* <ClientLogo logos={sectiondata.clientlogos} /> */}

            {/* NewsLetter */}
            {/* <NewsLetter newsLetterContent={sectiondata.calltoactions.newsletters} /> */}

            {/* Footer */}
            <Footer />

            <ScrollTopBtn />
        </main>
    )
}

export default Home;
