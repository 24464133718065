import React from "react";
// import SignInOptions from "./SignInOptions";
import { Link } from "react-router-dom";
// import { useHistory } from "react-dom";
import { AiOutlineUser } from "react-icons/ai";
import { FaRegEnvelope } from "react-icons/fa";
import { FiLock } from "react-icons/fi";
import { FaBusinessTime } from "react-icons/fa";
import { useState } from "react";
import axios from "axios";
import sectiondata from "../../../store/store";
import { useHistory } from "react-router-dom";
import Alert from "react-bootstrap/Alert";
import { FaPhone } from "react-icons/fa";
// import {  toast } from 'react-toastify';
function SignUpBox({ title, subtitle }) {
  const navigate = useHistory();
  const [secc, setSecc] = useState(false);
  const [err, seterr] = useState(false);

  const [errmes, setError] = useState("");

  const apiUrl = sectiondata.apiurl;
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    compName: "",
    title:"",
    contactNo: "",
    compPassword: "",
  });
  const [disable, setdisable] = useState(false);
  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };
  const handleSubmit = async (e) => {
    setdisable(true);
    e.preventDefault();
    // setError('');
    // setSuccess('');
    // setLoading(true); // Start loading animation

    try {
      const response = await axios.post(
        `${apiUrl}/hindu/company/compRegister`,
        formData
      );
      if (response.status === 201) {
        // console.log(response);
        // setSuccess('User registered successfully');
        setFormData({
          firstName: "",
          lastName: "",
          email: "",
          compName: "",
          title:"",
          contactNo: "",
          compPassword: "",
        });
       
        setSecc(true);
        setTimeout(() => {
          setSecc(false);
          navigate.push("/login");
        }, 3000); // 5000 milliseconds = 5 seconds

        // navigate("/login");
        // Redirect to login popup
        // setShowLoginPopup(true);
      }
    } catch (error) {
      console.error("error", error);
      seterr(true);
      setTimeout(() => {
        seterr(false);
      }, 5000);

      setError(error.response?.data?.message);
      // toast.success("error.response?.data?.message", {
      //     position: toast.POSITION.TOP_RIGHT,
      //   });
    } finally {
      setdisable(false); // Stop loading animation
    }
  };
  return (
    <>
      <div className="billing-form-item mb-0">
        <div className="billing-title-wrap border-bottom-0 pr-0 pl-0 pb-0 text-center">
          <h3 className="widget-title font-size-28 pb-0">{title}</h3>
          <p className="font-size-16 font-weight-medium">
            And start your Listing Now
          </p>
        </div>
        <div className="billing-content">
          <div className="contact-form-action">
            <form onSubmit={handleSubmit}>
              <div className="row">
                {/* <SignInOptions /> */}

                {/* <div className="col-lg-12">
                                    <div className="account-assist mt-4 mb-4 text-center">
                                        <p className="account__desc">or</p>
                                    </div>
                                </div> */}
                <div className="col-lg-12">
                  <div className="input-box">
                    <label className="label-text">Title</label>
                    <div className="form-group">
                      <span className="form-icon">
                        <AiOutlineUser />
                      </span>
                      <input
                        onChange={handleChange}
                        required
                        className="form-control"
                        type="text"
                        value={formData.title}
                        name="title"
                        placeholder="Mr,Mrs,Miss..."
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="input-box">
                    <label className="label-text">First name</label>
                    <div className="form-group">
                      <span className="form-icon">
                        <AiOutlineUser />
                      </span>
                      <input
                        onChange={handleChange}
                        required
                        className="form-control"
                        type="text"
                        value={formData.firstName}
                        name="firstName"
                        placeholder="First name"
                      />
                    </div>
                  </div>
                </div>
                
                <div className="col-lg-12">
                  <div className="input-box">
                    <label className="label-text">Last name</label>
                    <div className="form-group">
                      <span className="form-icon">
                        <AiOutlineUser />
                      </span>
                      <input
                        onChange={handleChange}
                        required
                        value={formData.lastName}
                        className="form-control"
                        type="text"
                        name="lastName"
                        placeholder="Last name"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="input-box">
                    <label className="label-text">Company Name</label>
                    <div className="form-group">
                      <span className="form-icon">
                        <FaBusinessTime />
                      </span>
                      <input
                        onChange={handleChange}
                        required
                        value={formData.compName}
                        className="form-control"
                        type="text"
                        name="compName"
                        placeholder="Company Name"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="input-box">
                    <label className="label-text">Email</label>
                    <div className="form-group">
                      <span className="form-icon">
                        <FaRegEnvelope />
                      </span>
                      <input
                        onChange={handleChange}
                        required
                        className="form-control"
                        value={formData.email}
                        type="email"
                        name="email"
                        placeholder="Enter email"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="input-box">
                    <label className="label-text">Phone</label>
                    <div className="form-group">
                      <span className="form-icon">
                        <FaPhone />
                      </span>
                      <input
                        required
                        onChange={handleChange}
                        value={formData.contactNo}
                        className="form-control"
                        type="number"
                        name="contactNo"
                        placeholder="Enter Phone"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="input-box">
                    <label className="label-text">Password</label>
                    <div className="form-group">
                      <span className="form-icon">
                        <FiLock />
                      </span>
                      <input
                        onChange={handleChange}
                        required
                        value={formData.compPassword}
                        className="form-control"
                        type="text"
                        name="compPassword"
                        placeholder="Password"
                      />
                    </div>
                  </div>
                </div>
                {/* <div className="col-lg-12">
                                    <div className="input-box">
                                        <label className="label-text">Confirm Password</label>
                                        <div className="form-group">
                                                <span className="form-icon">
                                                    <FiLock />
                                                </span>
                                            <input className="form-control" type="text" name="text" placeholder="Confirm password" />
                                        </div>
                                    </div>
                                </div> */}
                {/* <div className="col-lg-12">
                                    <div className="form-group">
                                        <div className="custom-checkbox d-block mr-0">
                                            <input  required type="checkbox" id="chb13" />
                                            <label htmlFor="chb13">I Agree to Dirto's <Link to="#" className="color-text">Privacy Policy</Link></label>
                                        </div>
                                        <div className="custom-checkbox d-block mr-0">
                                            <input type="checkbox" id="chb14" />
                                            <label htmlFor="chb14">I Agree to Dirto's <Link to="#" className="color-text">Terms of Services</Link></label>
                                        </div>
                                    </div>
                                </div> */}
                {secc &&
                  <div
                    class="alert alert-success "
                    style={{ margin: "auto" }}
                    role="alert"
                  >
                    Account Created Successfuly Login Now !
                  </div>}
                {err &&
                  <div
                    class="alert alert-danger "
                    style={{ margin: "auto" }}
                    role="alert"
                  >
                  User Already Exist or Something Went Wrong Try Again
                  </div>}
                
                {/* <div style={{ width: "100%" }}>
                  {secc && (
                    <Alert variant={"success"}>
                      Account Created Successfully
                      <Alert.Link href="/login">Login Now</Alert.Link>
                    </Alert>
                  )} */}
                  {err && <Alert variant={"danger"}>{errmes}</Alert>}
                </div>
                <div className="col-lg-12">
                  <div className="btn-box margin-top-20px margin-bottom-20px">
                    <button
                      disabled={disable}
                      className="theme-btn border-0"
                      type="submit"
                    >
                      Register account
                    </button>
                  </div>
                </div>

                <div className="col-lg-12">
                  <p className="font-weight-medium">
                    Already have an account?{" "}
                    <Link to="/login" className="color-text">
                      Login
                    </Link>
                  </p>
                </div>
              {/* </div> */}
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default SignUpBox;
