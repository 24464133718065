import React, { useEffect, useState } from "react";
import { FiPlus, FiPlusCircle, } from "react-icons/fi";
import { BsListCheck, BsPower } from "react-icons/bs";
import { AiOutlineUser } from "react-icons/ai";
import { Link } from "react-router-dom";
import Button from "../../common/Button";
import Cookies from "js-cookie";
import userimg from "../../../assets/images/usericon.jpeg";
// import Cookies from 'js-cookie';

export default function HeaderAuthorAccess() {
  const [AuthorAccessOpen, setAuthorAccessOpen] = useState(false);
  // console.log(
  //   Cookies.get("Fname"),
  //   Cookies.get("Lname"),
  //   Cookies.get("emial"),
  //   Cookies.get("mobile"),
  //   Cookies.get("company")
  // );
  const [lname, setLname] = useState("");
  const [fname, setfname] = useState("");
  const [cname, setCname] = useState("");
  const [isLogin, setIslogin] = useState(false);
  const token = Cookies.get("token")
  useEffect(() => {

    if (Cookies.get("token")) {
      setIslogin(true);
      setLname(Cookies.get("Lname"));
      setfname(Cookies.get("Fname"));
      setCname(Cookies.get("company"));
    }
  }, [token]);
  return (
    <>
      {isLogin &&
        <div className="logo-right-content">
          {/* <ul className="author-access-list">
          {!isLogin && (
            <li>
              <Link to="/login">login</Link>
              <span className="or-text">or</span>
              <Link to="/sign-up">Sign up</Link>
            </li>
          )}
          <li>
            <Button text="add listing" url={`${isLogin ? "/add-listing/new" :"login"}`}>
              <FiPlusCircle />
            </Button>
          </li>
        </ul> */}
          {isLogin && (
            <div
              className="side-user-menu-open"
              onClick={() => setAuthorAccessOpen(!AuthorAccessOpen)}
            >
              <AiOutlineUser />
            </div>
          )}
        </div>
      }

      {!isLogin &&
        <div className="logo-right-content">
          <ul className="author-access-list">
            {!isLogin && (
              <li>
                <Link to="/login">login</Link>
                <span className="or-text">or</span>
                <Link to="/sign-up">Sign up</Link>
              </li>
            )}
            <li>
              <Button text="add listing" url={`${isLogin ? "/add-listing/new" : "login"}`}>
                <FiPlusCircle />
              </Button>
            </li>
          </ul>

        </div>}

      {/* Side User panel */}
      <div
        className={
          AuthorAccessOpen ? "side-user-panel active" : "side-user-panel"
        }
      >
        <div className="humburger-menu">
          <div
            className="humburger-menu-lines side-menu-close"
            onClick={() => setAuthorAccessOpen(!AuthorAccessOpen)}
          ></div>
        </div>
        <div className="side-menu-wrap side-user-menu-wrap">
          <div className="side-user-img">
            <img src={userimg} alt="User" />
            <h4 className="su__name">
              {fname.replaceAll(`"`, "")} {lname.replaceAll(`"`, "")}
            </h4>
            <span className="su__meta">{cname.replaceAll(`"`, "")}</span>
            <span className="su__meta"></span>
            <div className="avatar-icon">
              <Link
                to="/dashboard"
                data-toggle="tooltip"
                data-placement="top"
                title="Change Avatar"
              >
                {" "}
                <FiPlus />
              </Link>
            </div>
          </div>

          <ul className="side-menu-ul">
            <li>
              <Link to="/dashboard">
                <AiOutlineUser className="user-icon" /> My Profile
              </Link>
            </li>
            <li>
              <Link to="/dashboard">
                <BsListCheck className="user-icon" /> My Listings
              </Link>
            </li>
            {/* <li><Link to="/dashboard"><FiBookmark className="user-icon" /> My Bookmarks</Link></li> */}
            <li>
              <Link to="/dashboard">
                <FiPlusCircle className="user-icon" /> add listing
              </Link>
            </li>
            <li>
              <div className="dropdown-divider"></div>
            </li>
            {/* <li><Link to="#"><BsQuestion className="user-icon" /> help</Link></li>
                        <li><Link to="#"><BsGear className="user-icon" /> Settings</Link></li> */}
            <li>
              <Link
                to="/"
                onClick={() => {
                  Cookies.remove("id");
                  Cookies.remove("Fname");
                  Cookies.remove("Lname");
                  Cookies.remove("token");
                  Cookies.remove("mobile");
                  Cookies.remove("company");
                  Cookies.remove("emial");
                }}
              >
                <BsPower className="user-icon" /> Sign Out
              </Link>
            </li>
          </ul>
          {/* <div className="side-user-search contact-form-action">
                        <form method="post">
                            <div className="form-group mb-0">
                                <FiSearch className="form-icon" />
                                <input className="form-control" type="search" name="search-field" placeholder="Search by keywords" />
                            </div>
                            <button type="button" className="theme-btn border-0">
                                Search
                            </button>
                        </form>
                    </div> */}
        </div>
      </div>
    </>
  );
}
