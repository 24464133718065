import React from "react";
import { useState,useEffect } from "react";
import Cookies from "js-cookie";
function FooterQuickLinkWidget({ footerquicklink }) {
  const token=Cookies.get("token")
    const [islogin,setislogin]=useState(false)
    useEffect(() => {
        if (Cookies.get("token")) {
            setislogin(true)
        }
    },[token])
  return (
    <>
      <div className="col-lg-3 column-td-6">
        <div className="footer-item">
          <h4 className="footer__title">{footerquicklink.title}</h4>
          <ul className="list-items">
            {footerquicklink.links.map((link, index) => {
              return (
                <li key={index}>
                  <a href={link.path}>{link.text}</a>
                </li>
              );
            })}
                      {islogin ?
                          <li>
                              <a href="/add-listing/new">add listing</a>
                          </li> : <li>
                              <a href="/login">add listing</a>
                          </li> }
          </ul>
        </div>
      </div>
    </>
  );
}

export default FooterQuickLinkWidget;
