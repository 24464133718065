import React from "react";
import { FiSearch } from "react-icons/fi";
import { useHistory } from "react-router-dom";
import Select from "react-select";
import { useState ,useEffect} from "react";
import axios from "axios";
import sectiondata from "../../../store/store";
const servicesArray = [
  "Aboriginal Service",
  "Accomodation",
  "Accountants",
  "Adult Services",
  "Advertising Marketing",
  "Aged Care",
  "Air BnB",
  "Air Conditioning",
  "Alcohol & Bottle Wine Shops",
  "Alternative Health",
  "Antennas",
  "Antiques",
  "Appliance Rental",
  "Appliance Repairs",
  "Appliances White Goods store",
  "Aquarium Fish",
  "Aquatic",
  "Archery",
  "Architect Services",
  "Art & Decorators",
  "Art Studio Gallery Exhibitions",
  "Astrology Clairvoyant Psychic",
  "Ayurveda",
  "Auction",
  "Audio Services",
  "Auto Mechanic",
  "Babies clothes & supplies",
  "Bakers Bakery Cakes",
  "Balloon",
  "Banks & Financial Institutions",
  "Bathroom Renovations",
  "Batteries",
  "BBQ & Heating",
  "Beauty Salon & Hairdressers",
  "Bicycles",
  "Billards",
  "Bins",
  "Blinds & Shutters",
  "Boat Sales & Hire",
  "Bookkeeping Services",
  "Books",
  "Boot Camp",
  "Bowling Club",
  "Brewing Co",
  "Brick Layers",
  "Builders & Developers",
  "Building Contractors",
  "Building Materials",
  "Business Consulting",
  "Business Directory",
  "Business Services",
  "Butchers Meat",
  "Cafes & Restaurants",
  "Cake & Pastry Shops",
  "Camping",
  "Car & Motor Vehicle Finance",
  "Car Accessories",
  "Car Dealers",
  "Car Rental & Hire",
  "Car Wash",
  "Caravans",
  "Career Counselling",
  "Carpenters & Joiners",
  "Carpet Cleaning",
  "Carpets Rugs",
  "Cash Advance",
  "Casinos",
  "Catering Equipment",
  "Catering Services",
  "Chamber of Commerce",
  "Charitable & Non-Profit Organizations",
  "Chemical",
  "Chemist Pharmacy",
  "Child Care",
  "Church",
  "Cinema Movies",
  "Civil Works",
  "Cleaning Services",
  "Clothing Alterations Tailor",
  "Clothing Apparel",
  "Clothing Store",
  "Club",
  "Club Badminton",
  "Club Baseball",
  "Club Bowling",
  "Club Casinos & Gambling",
  "Club Football",
  "Club Golf",
  "Club Netball",
  "Club Poker",
  "Club RSL",
  "Club Soccer",
  "Club Sports",
  "Club Youth",
  "Clubs Car Racing",
  "Coaching Tutoring",
  "Community Centre",
  "Computer Software",
  "Computer Store",
  "Concreting",
  "Council",
  "Couriers",
  "Cruises",
  "Currency Exchange",
  "Cycles",
  "Dance & Music Coaching",
  "Dance Carnival & Magic",
  "Dating Services",
  "Day SPA",
  "Demolition Construction",
  "Dentist",
  "Distribution",
  "Doctors & Medical Clinics",
  "Dog Washing & Grooming",
  "Doors & Windows",
  "Driving School",
  "Drone",
  "Dry Cleaners",
  "Education Consultant",
  "Electrical & Plumbing",
  "Electrical Contractors",
  "Electrical Store",
  "Electricity Supplier",
  "Electricity Resellers",
  "Electronics Store",
  "Elevator Service",
  "Employment Agency",
  "Energy Brokers Electricity & Gas",
  "Engineering Consultants",
  "Entertainment",
  "Environment",
  "Event Management",
  "Fabricators",
  "Fabrics",
  "Farmers",
  "Fashion & Clothing",
  "Fencing",
  "Film Production Services",
  "Financial Consultants",
  "Fire Services",
  "Firewood",
  "Fireworks",
  "Fishing",
  "Flooring Carpets & Tiles",
  "Florist",
  "Food & Groceries",
  "Food Deliveries",
  "Foreign Exchange",
  "Forklifts",
  "Framing and Fine Art",
  "Function Centre",
  "Fundraising",
  "Funeral Services",
  "Furniture Store",
  "Gambling & Lottery Services",
  "Gaming Venues",
  "Garden Nurseries Plants & Turfs",
  "Glazier & Glass",
  "Government",
  "GPS",
  "Granny Flats",
  "Graphic Design Services",
  "Gym Health & Fitness Centres",
  "Gymnastic Clubs",
  "Hair & Beauty",
  "Hair Removal",
  "Hair salon",
  "Handyman",
  "Hardware Store",
  "Health & Beauty",
  "Health Clinic",
  "Health Supplements Vitamins",
  "Healthcare",
  "Hearing Aid",
  "Hobbies",
  "Home Improvement",
  "Hospital",
  "Hostel",
  "Hotel Club Supplies",
  "Hotels & Pubs",
  "Hotels Motels & Resorts",
  "Ice Cream",
  "Industrial Equipment",
  "Industrial Manufacturer",
  "Infrastructure Development",
  "Insurance Brokers & Consultants",
  "Interior Design Services",
  "Internet Marketing",
  "Internet Services",
  "Internet Shopping",
  "Ironing Services",
  "IT Services",
  "Jewellers",
  "Joinery",
  "Jumping Castles",
  "Karate",
  "Kids Activities",
  "Kids clothes & supplies",
  "Kids Sports",
  "Kitchen Manufacturers & Renovators",
  "Laser & Skin Clinic",
  "Laundry Service",
  "Lawyers & Solicitors",
  "Leisure Centre",
  "Lighting Suppliers",
  "Locks & Locksmiths",
  "Logistics Trucks",
  "LPG & Gas",
  "Magazines & Periodicals",
  "Make-Up Artists & Supplies",
  "Management Consultants",
  "Manchester",
  "Manufacturing & Industrial",
  "Marine",
  "Market Research",
  "Marketing Advertising",
  "Martial arts school",
  "Massage",
  "Mechanical & Industrial Services",
  "Media & Marketing",
  "Media Consultants",
  "Medical Centre",
  "Medical diagnostic imaging center",
  "Medical Supply",
  "Meditation",
  "Migration Services",
  "Mobile Phone",
  "Mobility Scooters",
  "Mortgage & Finance Brokers",
  "Motor Cycle Sales & Hire",
  "Music",
  "Nail salon",
  "Naturopaths",
  "Newspaper Publishing & Printing",
  "Office & Business Systems",
  "Office Furniture & Equipment",
  "Office Supplies",
  "OHS - Occupational Health & Safety",
  "Optometrist Eye Care",
  "Organic Products",
  "Packaging & Labelling Services",
  "Packaging Service",
  "Painters & Decorators",
  "Party Supplies",
  "Pawn Shop & Pawnbrokers",
  "Personal trainer",
  "Pest Control",
  "Pet Housing",
  "Pet Shops & Accessories",
  "Petcare",
  "Petrol",
  "Pharmacy & Chemist",
  "Phone Repairs",
  "Photographers",
  "Physiotherapist Chiropractic",
  "Physiotherapy & Chiro",
  "Piano",
  "Picture Framing",
  "Pizza Restaurants",
  "Plumbers",
  "Podiatry",
  "Political Organisation",
  "Post Office",
  "Printing Equipment Photocopier",
  "Printing Company",
  "Property Developers",
  "Property Values",
  "Psychologist Hypnotherapy",
  "Public Relations Services",
  "Publishing Magazines",
  "Quality Control & Safety Consultants",
  "Radio",
  "Real Estate",
  "Recruitment",
  "Recruitment Agency",
  "Recycling & Waste Management",
  "Rehabilitation Centre",
  "Religious Organisation",
  "Removalist",
  "Restaurant",
  "Retail Merchandising Service",
  "Retail Shop",
  "Retirement Villages",
  "Robot Manufacturing",
  "Roof Restoration",
  "Safes & Vaults",
  "Safety Equipments",
  "School College",
  "School Supplies",
  "Scrap Metal Merchants",
  "Seafood",
  "Security Services",
  "Security Surveillance Products & Services",
  "Self Defence",
  "Self Storage",
  "Senior Services",
  "Service Station",
  "Serviced Offices",
  "Sewing Machine",
  "Shipping Container",
  "Shoes",
  "Shopping Centre",
  "Signs",
  "Smash Repairs",
  "Social Services",
  "Solar",
  "Sport & Entertainment Associations",
  "Sporting Venues & Stadiums",
  "Sports Store",
  "Staffing & Support Services",
  "Storage",
  "Strata Management",
  "Student Accommodation",
  "Student Services",
  "Supermarket",
  "Surveyors",
  "Swimming Classes",
  "Swimming Pool & Spa",
  "Tattoo",
  "Taxi Uber Ola Ride Share",
  "Technological Research & Development",
  "Telecommunication",
  "Telephone Services",
  "Tow Trucks",
  "Tradie",
  "Training & Development",
  "Translators & Interpreters",
  "Transport",
  "Travel Agents & Consultants",
  "Tree Lopper",
  "Trophy Medals Plaques",
  "Tuition Coaching",
  "Tyres",
  "University & Colleges",
  "Vacuum Cleaners",
  "Vaults",
  "Veterinary Pet Health",
  "Visa Immigration Services",
  "Vitamin & Health Food",
  "Wardrobes",
  "Washing Machines & Dryer",
  "Waste Management Recycle Rubbish",
  "Waste Water Treatment",
  "Water Filters Aqua",
  "Web-Site Design SEO",
  "Wedding Planners",
  "Weddings Marriage Celebrant",
  "Weight Loss",
  "Wellness center",
  "White Goods Electronic Store",
  "Wholesalers & Manufacturers",
  "Window Cleaning",
  "Window Tinting",
  "Wineries",
  "Yoga",
  "Youth organization",
  "Youth services",
  "Zoo",
];
const newone = servicesArray.map((each) => {
  const a = { value: each, label: each };
  return a;
});
const state = {
  selectedCatOp: null,
  categories: newone,
};

export default function BannerOneSearchInput() {
const navigate=useHistory()
  const [key, setKey] = useState("");
  const [stat, setState] = useState("");
    const [category, setCat] = useState("");
    // const selectCountry = () => {
    //     return (
    //       <>
    //             <Select
                    
    //           onChange={(e)=>{setState(e.value)}}
    //           placeholder="Select a State"
    //           options={[
    //             {
    //               value: "ACT",
    //               label: "ACT",
    //             },
    //             {
    //               value: "NSW",
    //               label: "NSW",
    //             },
    //             {
    //               value: "NT",
    //               label: "NT",
    //             },
    //             {
    //               value: "QLD",
    //               label: "QLD",
    //             },
    //             {
    //               value: "SA",
    //               label: "SA",
    //             },
    //             {
    //               value: "TAS",
    //               label: "TAS",
    //             },
      
    //             {
    //               value: "VIC",
    //               label: "VIC",
    //             },
    //             {
    //               value: "WA",
    //               label: "WA",
    //             },
    //           ]}
    //         />
    //       </>
    //     );
    // };
    const handleSearch = (e) => {
        e.preventDefault();
        if (key && !stat && !category) {
          navigate.push(`search?keyword=${key}`)  
        };
        if (!key && stat && !category) {
          navigate.push(`search?state=${stat}`)  
        };
        if (!key && !stat && category) {
          navigate.push(`search?category=${category}`)  
        };
        if (key && stat && !category) {
          navigate.push(`search?keyword=${key}&state=${stat}`)  
        };
        if (key && !stat && category) {
          navigate.push(`search?keyword=${key}&category=${category}`)  
        };
        if (!key && stat && category) {
          navigate.push(`search?state=${stat}&category=${category}`)  
        };
        if (key && stat && category) {
          navigate.push(`search?keyword=${key}&state=${stat}&category=${category}`)  
        };
  }
  
  const [data, setData] = useState([]);
  useEffect(() => {
    axios
      .get(`${sectiondata.apiurl}/hindu/company/getAllCategories`)
      .then((res) => {
        // console.log(res.data.data);
        const a = res.data.data
        const one = a.map((each) => {
          const a = { value: each.categoryName, label: each.categoryName};
          return a;
        });
        setData(one);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  

  return (
    <>
      <div className="main-search-input">
        <div className="main-search-input-item">
          <div className="contact-form-action">
            <form action="#">
              <div className="form-group mb-0">
                <span className="form-icon">
                  <FiSearch />
                </span>
                <input
                  className="form-control"
                                  type="text"
                                  value={key}
                                  onChange={(e)=>{setKey(e.target.value)}}
                  placeholder="What are you looking for?"
                />
              </div>
            </form>
          </div>
        </div>

        <div className="main-search-input-item location">
        <Select
                    
              onChange={(e)=>{setState(e.value)}}
              placeholder="Select a State"
              options={[
                {
                  value: "ACT",
                  label: "ACT",
                },
                {
                  value: "NSW",
                  label: "NSW",
                },
                {
                  value: "NT",
                  label: "NT",
                },
                {
                  value: "QLD",
                  label: "QLD",
                },
                {
                  value: "SA",
                  label: "SA",
                },
                {
                  value: "TAS",
                  label: "TAS",
                },
      
                {
                  value: "VIC",
                  label: "VIC",
                },
                {
                  value: "WA",
                  label: "WA",
                },
              ]}
            />
        </div>

        <div className="main-search-input-item category">
          <Select
            onChange={(e) => {
              setCat(e.value);
            }}
            placeholder="Select a Category"
            options={data}
          />
        </div>

        <div className="main-search-input-btn">
          <button className="button theme-btn" onClick={(e)=>{handleSearch(e)}} type="submit">
            Search
          </button>
        </div>
      </div>
    </>
  );
}
